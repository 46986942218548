import { Entity, IComponent, Vector2D } from '@/utils';
import { Tile } from '@/tile';
import { Faction, FactionManager } from '@/faction';
import * as PIXI from 'pixi.js';
import { DoorDrawComponent } from '@/door';

export class Door extends Entity {
  constructor(direction: string, tile) {
    super();
    if (tile) {
      this.Tile = tile;
      this.Position = new Vector2D(this.Tile.Start.x, this.Tile.Start.y);
      this.Index = new Vector2D(this.Tile.Index.x, this.Tile.Index.y);
    }
    this.Direction = direction;
  }
  public Entity: Entity | null;
  public Tile: Tile;
  public Position: Vector2D;
  public Index: Vector2D;
  public Faction: Faction | null;
  private _sprite: any;
  public Name = 'Door';
  public Description = 'fear and love open the doors';
  public UI: any;
  public Blocking: boolean = true;
  public DrawComponent: any;
  public Direction: string;

  public Awake(): void {
    if (this.Tile) {
      this.DrawComponent = new DoorDrawComponent();
      this.AddComponent(this.DrawComponent);
      // this.DrawComponent.Awake()
    }

    super.Awake();
    // this.Open()
  }

  public disableBlocking(): void {
    this.Blocking = false;
  }

  public Open(): void{
    this.DrawComponent.Open()
    this.disableBlocking()
  }
}
