import { devRoom, Entity, IComponent, Vector2D } from '@/utils';
import { Player } from '@/player';
import { Tile } from '@/tile';
import { LevelManager } from '@/levelManager';
import * as TWEEN from '@tweenjs/tween.js';
import { PlayerCombatComponent } from '@/player/components/combat/combat';
import { Settings } from '@/settings';
import { Door } from '@/door';
import * as PF from '@/assets/pathfinding-browser';
import { FactionManager } from '@/faction';
import { Level } from '@/level';
import { BaseItem } from '@/items';
import { Enemy } from '@/enemy/enemy';

export class PlayerLocomotionComponent implements IComponent {
  public Entity: Player;

  private _tile: Tile | null = null;

  public get Tile(): Tile | null {
    return this._tile;
  }

  public set Tile(v: Tile | null) {
    this._tile = v;
  }

  public get Position(): Vector2D | null {
    return this.Tile ? this.Tile.Center : null;
  }

  public get Index(): Vector2D | null {
    return this.Tile ? this.Tile.Index : null;
  }

  public get Start(): Vector2D | null {
    return this.Tile ? this.Tile.Start : null;
  }

  public get Center(): Vector2D | null {
    return this.Tile ? this.Tile.Center : null;
  }

  public MoveUpLeft(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(-1, -1);
      return;
    }
    const thisTile = this._canMove();
    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x - 1,
        y: thisTile.Index.y - 1,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }

      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }
        this.HandleAttack(targetEntities);

        return;
      }

      this.Tile = targetTile;

      this.tweenMovement();
      return;
    }
  }

  public MoveUp(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(0, -1);
      return;
    }
    const thisTile = this._canMove();
    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x + 0,
        y: thisTile.Index.y - 1,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }
        this.HandleAttack(targetEntities);

        return;
      }

      this.Tile = targetTile;

      this.tweenMovement();
      return;
    }
  }

  public Stay(): void {
    if (this.Entity.ViewMode) {
      return;
    }
    this.Entity.Moved = false;
    this.Entity.MovementPoints -= 1;
    return;
  }

  public MoveUpRight(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(1, -1);
      return;
    }
    if (this.Entity.ViewMode) {
      this.HandleViewMove(1, -1);
    }
    const thisTile = this._canMove();
    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x + 1,
        y: thisTile.Index.y - 1,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }
        this.HandleAttack(targetEntities);

        return;
      }

      this.Tile = targetTile;

      this.tweenMovement();
      return;
    }
  }

  public MoveRight(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(1, 0);
      return;
    }
    const thisTile = this._canMove();
    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x + 1,
        y: thisTile.Index.y,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }

        this.HandleAttack(targetEntities);

        return;
      }

      this.Tile = targetTile;
      this.tweenMovement();
      return;
    }
  }

  public MoveDownRight(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(1, 1);
      return;
    }

    const thisTile = this._canMove();
    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x + 1,
        y: thisTile.Index.y + 1,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }
        this.HandleAttack(targetEntities);

        return;
      }
      this.Tile = targetTile;
      this.tweenMovement();
      return;
    }
  }

  public MoveDown(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(0, 1);
      return;
    }
    const thisTile = this._canMove();

    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x,
        y: thisTile.Index.y + 1,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }

        this.HandleAttack(targetEntities);

        return;
      }
      this.Tile = targetTile;
      this.tweenMovement();
      return;
    }
  }

  public MoveDownLeft(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(-1, +1);
      return;
    }
    const thisTile = this._canMove();

    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x - 1,
        y: thisTile.Index.y + 1,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          this.Entity.MovementPoints -= 1;
          return;
        }
        this.HandleAttack(targetEntities);

        return;
      }
      this.Tile = targetTile;
      this.tweenMovement();
      return;
    }
  }

  public MoveLeft(): void {
    if (this.Entity.ViewMode) {
      this.HandleViewMove(-1, 0);
      return;
    }
    const thisTile = this._canMove();
    if (thisTile) {
      const targetIndex = {
        x: thisTile.Index.x - 1,
        y: thisTile.Index.y + 0,
      };
      const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
      const targetEntities = LevelManager.Active.getEntitiesAtTile(
        targetTile.Index
      );
      let entity = targetEntities[0];
      if (entity instanceof Door) {
        this.handleDoor(entity);

        return;
      }
      if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
        if (targetEntities == false) {
          this.Entity.Moved = false;
          return;
        }
        this.HandleAttack(targetEntities);

        return;
      }

      this.Tile = targetTile;

      this.tweenMovement();
      return;
    }
  }

  public MoveToTile(targetIndex: Vector2D): void {
    const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
    if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) return;
    this.Tile = targetTile;

    this.tweenMovement();
  }

  public tweenMovement(): void {
    if (this.Entity.Start && this.Entity.Start.x > -1 && this.Tile) {
      new TWEEN.Tween(this.Entity.Start)
        .to(this.Tile.Start, Settings.tweenDuration)
        .easing(TWEEN.Easing.Quadratic.Out)
        .start()
        .onComplete(
          (): void => {
            this.Entity.Moved = false;
            this.Entity.MovementPoints -= 1;
            return;
          }
        );
    }
  }

  public HandleAttack(entities: Entity[]): void {
    entities.forEach(
      (entity: Entity): void => {
        if (entity.HasComponent(PlayerCombatComponent)) {
          this.Entity.CombatComponent.AttackEntity(
            entity.GetComponent(PlayerCombatComponent)
          );
        }
      }
    );
  }

  public HandleViewMove(x: number, y: number): void {
    const targetIndex = {
      x: this.Entity.ViewTile.Index.x + x,
      y: this.Entity.ViewTile.Index.y + y,
    };
    if (
      targetIndex.x > 22 ||
      targetIndex.x < 0 ||
      targetIndex.y > 22 ||
      targetIndex.y < 0
    ) {
      return;
    }
    const newViewTile = LevelManager.Active.GetTileByIndex(
      new Vector2D(targetIndex.x, targetIndex.y)
    );
    this.Entity.ViewTile.IsActive = false;
    this.Entity.ViewTile = newViewTile;
    this.Entity.ViewTile.IsActive = true;
    const grid = new PF.Grid(devRoom);
    const finder = new PF.AStarFinder({ allowDiagonal: true });
    const path = finder.findPath(
      this.Tile.Index.x,
      this.Tile.Index.y,
      this.Entity.ViewTile.Index.x,
      this.Entity.ViewTile.Index.y,
      grid
    );
    this.Entity.ViewTileDistance = path.length - 1;

    const targetEntities = LevelManager.Active.getEntitiesAtTile(
      this.Entity.ViewTile.Index
    );

    if (targetEntities) {
      let entity = targetEntities[0];

      this.Entity.Preview._currentName = entity.Description;
      this.Entity.Preview._currentDescription = entity.Name;
    }
  }

  public Awake(): void {
    /* @todo */
  }

  public Update(deltaTime: number): void {
    /* @todo */
  }

  private _canMove(): Tile | false {
    if (
      this.Entity.Start.x === this.Tile.Start.x &&
      this.Entity.Start.y === this.Tile.Start.y
    ) {
      if (this.Entity.MovementPoints < 1 || TWEEN.getAll().length > 0) return;

      this.Entity.Moved = true;
      return this._tile;
    }

    return false;
  }

  private _getEntitesByIndex(targetIndex: Vector2D): Entity[] | false {
    const targetTile = LevelManager.Active.GetTileByIndex(targetIndex);
    if (LevelManager.Active.isWalkableTile(targetTile.Index) == false) {
      return LevelManager.Active.getEntitiesAtTile(targetTile.Index);
    }
    return false;
  }

  private handleDoor(door: Door): void {
    if (door.Blocking == true) {
      this.Entity.Moved = false;
      this.Entity.MovementPoints -= 1;
      // return;
    } else {
      this.MoveToTile(door.Tile.Index);

      let newDirection;
      let newMap;
      if (door.Direction === 'left') {
        FactionManager.Player.left += 1;
        newDirection = 'right';
        newMap = 3;
      }
      if (door.Direction === 'right') {
        FactionManager.Player.right += 1;
        newDirection = 'left';
        newMap = 5;
      }
      if (door.Direction === 'up') {
        FactionManager.Player.up += 1;
        newDirection = 'down';
        newMap = 1;
      }
      if (door.Direction === 'down') {
        FactionManager.Player.down += 1;
        newDirection = 'up';
        newMap = 7;
      }
      if (LevelManager.Active.Locked == false) {
        FactionManager.Player.Level += 1;
        LevelManager.Active.resetLevel();
        FactionManager.NeutralFaction.ResetItems();
        LevelManager.Active = LevelManager.Levels[newMap];
        LevelManager.Active.Tiles = LevelManager.Levels[newMap].Tiles;
        LevelManager.Levels[4] = LevelManager.Active;

        if (LevelManager.Active.Type == 'forest') {
          FactionManager.Player.Forest += 1;
        }
        if (LevelManager.Active.Type == 'beach') {
          FactionManager.Player.Beach += 1;
        }
        if (LevelManager.Active.Type == 'mountain') {
          FactionManager.Player.Mountain += 1;
        }

        let cDamage: any = 0;
        let cArmor: any = 0;
        let cMp: any = 0;
        let cRange: any = 0;
        let cMaxHealth: any = 0;

        let playerInv = FactionManager.Player.Inventory;


        for (let item of playerInv._entities) {
          if (item.occupied) {
            item.occupied.Update()

            cDamage += item.occupied._cDamage;
            cArmor += item.occupied._cArmor;
            cMp += item.occupied._cMp;
            cRange += item.occupied._cRange;
            cMaxHealth += item.occupied._cMaxHealth;
          }
        }
        FactionManager.Player._cDamage  = cDamage
        FactionManager.Player._cArmor = cArmor
        FactionManager.Player._cMp = cMp
        FactionManager.Player._cRange = cRange
        FactionManager.Player._cMaxHealth = cMaxHealth



        // LevelManager.Active.Awake()
        LevelManager.Active.InitStuff();

        let newDoor = LevelManager.Active.Doors.find(door => {
          return door.Direction == newDirection;
        });
        this.Tile = newDoor.Tile;
        this.Entity.Start = new Vector2D(
          newDoor.Tile.Start.x,
          newDoor.Tile.Start.y
        );
        this.Entity.ViewTile = null;
        this.MoveToTile(newDoor.Tile.Index);
        LevelManager.Active.Awake();
        FactionManager.EnemyFaction.Awake();

        LevelManager.Regenerate();
      }
    }
  }
}
