import * as PIXI from 'pixi.js';

export function arrowEffect(): PIXI.Graphics {
  let projectileEffect = new PIXI.Graphics();
  projectileEffect.beginFill(0x181818);
  projectileEffect.drawRect(0, 0, 6, 6);
  projectileEffect.endFill();

  return projectileEffect;
}
