import * as PIXI from 'pixi.js';
require('pixi-sound');
import { LevelManager } from '@/levelManager';
import { Game } from '@/game';

function setup(): void {
  const level = LevelManager.Active;
  new Game(level).Awake();
}

document.onload = () => {
  this.contentWindow.focus();
};
PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH;
const loader = PIXI.Loader.shared;

loader
  .add({
    name: 'background',
    url: 'src/assets/LizardmenSwampTileset.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'backgroundLizardTrees',
    url: 'src/assets/LizardmenTrees.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'backgroundAllTrees',
    url: 'src/assets/AllTreesMountains.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'player',
    url: 'src/assets/CreatureSpriteSheet2.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'mace',
    url: 'src/assets/MaceOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'undeadEnemy',
    url: 'src/assets/NecromancerSpriteSheet.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'undeadEnemyIcon',
    url: 'src/assets/NecromancerIcons.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'artifacts',
    url: 'src/assets/ArtifactOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'arrows',
    url: 'src/assets/ArrowOrigin.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'dungeonTiles',
    url: 'src/assets/Dungeon-Tileset.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'mountainTiles',
    url: 'src/assets/LB-LandTileset.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'beachTiles',
    url: 'src/assets/SB-LandTileset.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'tower',
    url: 'src/assets/TowerSpriteSheet.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'towerIcons',
    url: 'src/assets/TowerIcons.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'stronghold',
    url: 'src/assets/StrongholdSpriteSheet.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'strongholdIcons',
    url: 'src/assets/StrongholdIcons.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'rampart',
    url: 'src/assets/RampartSpriteSheet.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'xmas',
    url: 'src/assets/CharactersSpriteSheet.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'rampartIcons',
    url: 'src/assets/RampartIcons.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'naga',
    url: 'src/assets/Naga.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'nagaIcon',
    url: 'src/assets/IconNaga.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'shield',
    url: 'src/assets/ShieldOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'feet',
    url: 'src/assets/FeetOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'instruments',
    url: 'src/assets/Items.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'ranged',
    url: 'src/assets/RangedOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'warlock',
    url: 'src/assets/WarlockOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'sword',
    url: 'src/assets/SwordOriginal.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'keyboard',
    url: 'src/assets/_sheet_keyboard.png',
    crossOrigin: 'anonymous',
  })
  .add({
    name: 'song',
    url: 'src/assets/LoneWoods.mp3',
    crossOrigin: 'anonymous',
  })
  .load(setup);
