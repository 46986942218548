import { Entity } from '@/utils';
import { GameLogUIComponent } from '@/gameLog/draw';

export class GameLog extends Entity {
  public Messages: string[] = [];
  public Status: string = ""

  public Awake(): void {
    this.AddComponent(new GameLogUIComponent());
    super.Awake();
  }

  public Update(deltaTime: number): void {
    super.Update(deltaTime);
  }

  public Add(message: string) {
    this.Messages.push(message);
    if (this.Messages.length > 18) {
      this.Messages = this.Messages.slice(1, 19);
    }
  }
  public SetStatus(status: string){
    this.Status = status
  }
}
