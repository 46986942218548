import { Entity } from '@/utils';

export const tm = {
  interval: 150,
  entities: new Set(),
  lastCall: Date.now(),
  currentIndex: 0,
  addEntity: (entity: Entity): any => tm.entities.add(entity),
  removeEntity: (entity: Entity): any => tm.entities.delete(entity),
  Refresh: (): void => {
    tm.entities.forEach((e: any) => {
      e.Refresh();
    });
    tm.currentIndex = 0;
  },

  Turn: (): void => {
    if (tm.entities.size > 0) {
      const entities = [...tm.entities];
      const e: any = entities[tm.currentIndex];

      if (!e.Over()) {
        e.Turn();
      } else {
        tm.currentIndex++;
      }
    }
  },
  Over: (): any => [...tm.entities].every((e: any): any => e.Over()),
};
