import { IComponent } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { Game } from '@/game';
import { GameLog } from '@/gameLog';
import { slotLocation } from '@/utils';

export class PreviewUIComponent implements IComponent {
  public Entity: Game;
  public _previewUI: any;
  public _legendText: any;
  private _height: any = Settings.grid.height * Settings.tileSize;
  public Description: any;
  public Name: any;
  public _currentDescription: string;
  public _currentName: string;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._previewUI = new PIXI.Graphics();
    this._previewUI.beginFill(0xa9a9a9);
    this._previewUI.drawRect(
      1,
      (height / 11) * 9 - 1,
      width - 2,
      (height / 11) * 2 - 0.5
    );
    this._previewUI.zIndex = 2;
    this._previewUI.endFill();

    this._previewUI.addChild(this._info());
    this._previewUI.addChild(this._name());

    // this.Draw();
  }

  public _info(): PIXI.Text {
    const height = Settings.grid.height * Settings.tileSize;

    const info = new PIXI.Text(`Move or press an item to inspect`, {
      fontFamily: Settings.ui.font,
      fontSize: 6,
      whitespace: 'nowrap',
      padding: 1,
      // align: 'center'
    });
    info.resolution = 2;
    info.position.x = 10;
    info.position.y = (height / 11) * 9 + 25;

    this.Name = info;

    return info;
  }

  public _name(): PIXI.Text {
    const height = Settings.grid.height * Settings.tileSize;

    const info = new PIXI.Text(``, {
      fontFamily: Settings.ui.font,
      fontSize: 6,
      whitespace: 'nowrap',
      padding: 1,
      // align: 'center'
    });
    info.resolution = 2;
    info.position.x = 10;
    info.position.y = (height / 11) * 9 + 12;

    this.Description = info;

    return info;
  }

  public Update(deltaTime: number): void {
    this._currentDescription &&
      (this.Description.text = this._currentDescription);
    this._currentName && (this.Name.text = this._currentName);
  }

  public Draw(): void {
    CanvasLayer.LeftUI.addChild(this._previewUI);
  }

  public Hide(): void {
    CanvasLayer.LeftUI.removeChild(this._previewUI);
  }
}
