import { Entity, IComponent, Vector2D } from '@/utils';
import { Tile } from '@/tile';
import { ItemDrawComponent } from '@/items/draw/draw';

export abstract class BaseItem extends Entity {
  constructor(tile: Tile = undefined) {
    super();
  }

  public abstract Entity: Entity | null;
  public abstract Tile: Tile | null;
  public abstract Sprite: any;
  public abstract Name: string;
  public abstract Description: string;
  public abstract Rarity: number;
  public abstract Blocking: false;
  public Position: Vector2D | null;
  public Quantity: any;
  public abstract UI: any;
  public DrawComponent: any;
  public Flipped: boolean;
  public Slot: number | null;
  public rangedDamage: number;
  public Projectile: boolean;
  public _damage: number = 0;
  public _armor: number = 0;
  public _mp: number = 0;
  public _range: number = 0;
  public _maxHealth: number = 0;

  public _cDamage: any = 0
  public _cArmor: any = 0
  public _cMp: any = 0
  public _cRange: any = 0
  public _cMaxHealth: any = 0

  // public abstract Update(deltaTime: number): void{
  //   super.Update()
  // }
  public abstract Damage(): number;

  public abstract Armor(): number;

  public abstract Use(): any;

  public abstract turn(): void;

  public abstract refresh(): void;

  public abstract over(): void;

  public abstract AddDrawComponent(): void;
}
