import * as PIXI from 'pixi.js';
import { randomInt } from '@/utils';

export function makeMountainGroundSprite(x, y): PIXI.Sprite {
  const spritePosition = [1, 0];

  const texture = new PIXI.Texture(PIXI.utils.TextureCache['mountainTiles']);
  const rectangle = new PIXI.Rectangle(
    spritePosition[0] * 16,
    spritePosition[1] * 16,
    16,
    16
  );
  texture.frame = rectangle;
  const groundSprite = new PIXI.Sprite(texture);
  groundSprite.x = x;
  groundSprite.y = y;

  if (Math.random() < 0.33) {
    const objectSpritePosition = [
      [2,  3, 4, 5, 5, 5, 5, 8, 8, 8, 8, 9, 8, 8, 8, 7, 11, 12][
        randomInt(18)
      ],
      [0, 1][randomInt(2)],
    ];
    const objectTexture = new PIXI.Texture(
      PIXI.utils.TextureCache['mountainTiles']
    );
    const objectRectangle = new PIXI.Rectangle(
      objectSpritePosition[0] * 16,
      objectSpritePosition[1] * 16,
      16,
      16
    );
    objectTexture.frame = objectRectangle;
    const objectSprite = new PIXI.Sprite(objectTexture);
    groundSprite.addChild(objectSprite);
  }

  groundSprite.addChild(makeBorder());
  groundSprite.zIndex = 2;
  return groundSprite;
}

export function makeMountainWallSprite(x, y): PIXI.Sprite {
  const spritePosition = [1, 0];
  const texture = new PIXI.Texture(PIXI.utils.TextureCache['mountainTiles']);
  const rectangle = new PIXI.Rectangle(
    spritePosition[0] * 16,
    spritePosition[1] * 16,
    16,
    16
  );
  texture.frame = rectangle;
  const wallSprite = new PIXI.Sprite(texture);
  wallSprite.x = x;
  wallSprite.y = y;

  //[3, 24], [3, 23],
  const treeSpritePosition = [[29, 23], [29, 24], [42, 23], [42, 24]][
    randomInt(4)
  ];
  const treeTexture = new PIXI.Texture(
    PIXI.utils.TextureCache['backgroundAllTrees']
  );
  const treeRectangle = new PIXI.Rectangle(
    treeSpritePosition[0] * 16,
    treeSpritePosition[1] * 16,
    16,
    16
  );
  treeTexture.frame = treeRectangle;
  const treeSprite = new PIXI.Sprite(treeTexture);
  wallSprite.addChild(treeSprite);
  wallSprite.zIndex = 1;

  return wallSprite;
}

function makeBorder(): PIXI.Graphics {
  let groundSpriteGraphics = new PIXI.Graphics();
  groundSpriteGraphics.beginFill();
  groundSpriteGraphics.lineStyle(0.3, 0x000000);
  groundSpriteGraphics.lineTo(0, 0);
  groundSpriteGraphics.lineTo(0, 16);
  groundSpriteGraphics.endFill();
  groundSpriteGraphics.beginFill();
  groundSpriteGraphics.lineTo(0, 0);
  groundSpriteGraphics.lineTo(16, 0);
  groundSpriteGraphics.endFill();
  groundSpriteGraphics.beginFill();
  groundSpriteGraphics.moveTo(16, 0);
  groundSpriteGraphics.lineTo(16, 16);
  groundSpriteGraphics.endFill();
  groundSpriteGraphics.beginFill();
  groundSpriteGraphics.moveTo(0, 16);
  groundSpriteGraphics.lineTo(16, 16);
  groundSpriteGraphics.endFill();
  return groundSpriteGraphics;
}
