import * as PIXI from 'pixi.js';
window.PIXI = PIXI; // this seems optional
import sound from 'pixi-sound'

export interface IKey {
  value: string;
  isDown: boolean;
  isUp: boolean;
  press: Function | undefined;
  release: Function | undefined;
  downHandler: Function | undefined;
  upHandler: Function | undefined;
  unsubscribe: Function | undefined;
}

export function KeyboardManager(value: string): IKey {
  const key: IKey = {
    value: value,
    isDown: false,
    isUp: false,
    press: undefined,
    release: undefined,
    downHandler: undefined,
    upHandler: undefined,
    unsubscribe: undefined,
  };
  key.value = value;
  key.isDown = false;
  key.isUp = true;
  key.press = undefined;
  key.release = undefined;
  //The `downHandler`
  key.downHandler = (e: any): void => {
    if (e.key === key.value) {
      if (key.isUp && key.press) key.press();
      key.isDown = true;
      key.isUp = false;
      e.preventDefault();
    }
  };

  //The `upHandler`
  key.upHandler = (e: any): void => {
    if (e.key === key.value) {
      if (key.isDown && key.release) key.release();
      key.isDown = false;
      key.isUp = true;
      e.preventDefault();
    }
  };

  //Attach event listeners
  const downListener = key.downHandler.bind(key);
  const upListener = key.upHandler.bind(key);

  window.addEventListener('keydown', downListener, false);
  window.addEventListener('keyup', upListener, false);

  // Detach event listeners
  key.unsubscribe = (): void => {
    window.removeEventListener('keydown', downListener);
    window.removeEventListener('keyup', upListener);
  };

  return key;
}
