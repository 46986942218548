import { devRoom, Entity, Vector2D } from '@/utils';
import { Faction, FactionManager } from '@/faction';
import {
  PlayerDrawComponent,
  PlayerLocomotionComponent,
  PlayerUIComponent,
  PlayerKeyInputComponent,
  PlayerCombatComponent,
} from './components';
import { Tile } from '@/tile';
import { Inventory } from '@/inventory';
import { LevelManager } from '@/levelManager';
import { BaseItem } from '@/items';
import { Game } from '@/game';
import { CanvasLayer } from '@/canvasLayer';
import { ItemDrawComponent } from '@/items/draw/draw';
import * as PF from '@/assets/pathfinding-browser';
import { PreviewUIComponent } from '@/game/components/preview/preview';

export class Player extends Entity {
  public Name: string = 'you';
  private _start: Vector2D | undefined;
  public _locomotionComponent: PlayerLocomotionComponent;
  public CombatComponent: PlayerCombatComponent;
  public UIComponent: PlayerUIComponent;
  public Inventory: Inventory;
  public Blocking: true = true;
  public Active: boolean;
  public ViewMode: boolean = false;
  public ShootMode: boolean = false;
  public ViewTile: Tile | null;
  public ViewTileDistance: number | null;
  public Range: number = 5;
  public Level: number = 1;
  public Forest: number = 0;
  public Mountain: number = 0;
  public Beach: number = 0;
  public up: number = 0;
  public right: number = 0;
  public down: number = 0;
  public left: number = 0;
  public Preview: PreviewUIComponent;
  public DropMode: boolean = false;
  public Health: number = 5;
  public MaxHealth: number = 5;
  public BaseArmor: number = 0;
  public BaseDamage: number = 1;
  public MovementPoints: number;

  public _cDamage = 0;
  public _cArmor = 0;
  public _cMp = 0;
  public _cRange = 0;
  public _cMaxHealth = 0;

  public get Position(): Vector2D | null {
    return this._locomotionComponent.Position;
  }

  public get Index(): Vector2D | null {
    return this._locomotionComponent.Index;
  }

  public get Center(): Vector2D | null {
    return this._locomotionComponent.Center;
  }

  public get Start(): Vector2D | undefined {
    return this._start;
  }

  public set Start(start: Vector2D | undefined) {
    this._start = start;
  }

  public get Tile(): Tile | null {
    return this._locomotionComponent.Tile;
  }
  constructor(
    public readonly Faction: Faction,
    tile: Tile,
    public MaxMovementPoints: number = 1,
    public Moved: boolean = false
  ) {
    super();
    this.MovementPoints = MaxMovementPoints;
    this._locomotionComponent = new PlayerLocomotionComponent();
    this._locomotionComponent.Tile = tile;
    this.Faction.AddEntity(this);
    this.CombatComponent = new PlayerCombatComponent();
    this.UIComponent = new PlayerUIComponent();
    this.Preview = new PreviewUIComponent();
    this.Inventory = new Inventory();
    this.Inventory.Owner = this;
    this.Inventory.Awake();
  }

  public Awake(): void {
    this.AddComponent(this.Preview);
    this.AddComponent(this._locomotionComponent);
    this.AddComponent(new PlayerKeyInputComponent());
    this.AddComponent(new PlayerDrawComponent());
    this.AddComponent(this.UIComponent);
    this.AddComponent(this.CombatComponent);

    //reset player start just in case something messes up
    if (this.Tile) {
      const start = new Vector2D(this.Tile.Start.x, this.Tile.Start.y);
      this._start = start;
    }

    super.Awake();
  }

  public Update(deltaTime: number): void {
    super.Update(deltaTime);

    for (const component of this.Components) {
      component.Update(deltaTime);
    }
  }

  public Turn(): void {}

  public Take(): void {
    const items = LevelManager.Active.getEntitiesAtTile(this.Tile.Index);

    if (
      items &&
      items.some(item => {
        return item instanceof BaseItem;
      })
    ) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item instanceof BaseItem) {
          CanvasLayer.Foreground.removeChild(item.Sprite);

          item.RemoveComponent(ItemDrawComponent);
          item.Position = null;
          item.Tile = null;

          this.Inventory.AddItem(item);
          Game.Log.Add(`you picked up ${item.Name}`);
          return;
        }
      }
    }
    Game.Log.Add(`No item to take`);
  }

  public UseItem(slot: number) {
    if (this.Inventory) {
      const items = this.Inventory.GetItems();
      for (let i = 0; i < items.length; i++) {
        if (items[i].slot == slot - 1)
          if (slot - 1 === i) {
            if (items[i].occupied instanceof BaseItem) {
              if (this.ViewMode && !this.ShootMode) {
                this.Preview._currentName = items[i].occupied.Description;
                this.Preview._currentDescription = items[i].occupied.Name;
              }
              if (this.DropMode) {
                this.Inventory.RemoveItem(items[i].occupied);
                this.ToggleDrop();
                return;
              } else {
                items[i].occupied.Use();
              }
            }
            return;
          }
      }
    }
  }

  public Over(): boolean {
    const isOver = this.MovementPoints < 1 && !this.Moved;

    if (isOver && this.UIComponent) {
      this.Active = false;
    } else {
      this.Active = true;
    }

    return isOver;
  }
  public Refresh(): void {
    this.MovementPoints = this.MaxMovementPoints + this._cMp;
    this.Moved = false;
  }

  public ToggleView(): void {
    this.ViewMode = !this.ViewMode;
    if (this.ViewMode == false) {
      this.Preview.Hide();
      this.ShootMode = false;
      this.ViewTile && (this.ViewTile.IsActive = false);
      this.ViewTile = null;
      this.ViewTileDistance = null;
    } else {
      this.ViewTile = this.Tile;
      this.ViewTile.IsActive = true;
      // let entities = LevelManager.Active.getEntitiesAtTile(this.ViewTile.Index)

      this.Preview.Draw();
      this.Preview.Update(performance.now())
    }
  }

  public ToggleDrop(): void {
    this.DropMode = !this.DropMode;
  }

  public Back(): void {
    this.DropMode = false;
    this.ViewMode = false;
    this.Preview.Hide();
    this.ShootMode = false;
    this.ViewTile && (this.ViewTile.IsActive = false);
    this.ViewTile = null;
    this.ViewTileDistance = null;
  }
}
