import { IComponent, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { OutlineFilter } from '@pixi/filter-outline';
import { BaseItem } from '@/items';

export class ItemDrawComponent implements IComponent {
  public Entity: BaseItem;
  public _itemSprite: any;

  private get Position(): Vector2D {
    if (!this.Entity) return;

    const position = this.Entity.Position;
    if (!position) {
      throw new Error('Attempt to draw an item that has no Position');
    }

    return position;
  }

  public Awake(): void {
    if (!this.Entity) return;
    this.Entity.Sprite;
    this.Entity.Sprite.setTransform(
      this.Position.x,
      this.Position.y,
      0.95,
      0.8
    );
    // this.Entity.Sprite.anchor.set(-0.15, 0);

    this.Entity.Sprite.filters = [new OutlineFilter(2, 0x00000)];
    if (this.Entity.Flipped) {
      this.Entity.Sprite.setTransform(
        this.Position.x + 2 + 15,
        this.Position.y,
        -0.95,
        0.95
      );
      this.Entity.Sprite.anchor.set(-0.1, 0);
    }
    this.Draw();
  }

  public Update(deltaTime: number): void {
    this.Entity.Sprite.x = this.Position.x - 0.5;
    this.Entity.Sprite.y = this.Position.y - 1;
  }

  private Draw(): void {
    CanvasLayer.Foreground.addChild(this.Entity.Sprite);
  }
}
