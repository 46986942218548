import { Entity, IComponent, Vector2D } from '@/utils';
import { Tile } from '@/tile';
import { BaseItem } from '@/items/baseItem';
import { Faction, FactionManager } from '@/faction';
import * as PIXI from 'pixi.js';
import { ItemDrawComponent } from '@/items/draw/draw';
import { Inventory } from '@/inventory';
import { Game } from '@/game';
import * as TWEEN from '@tweenjs/tween.js';

export class HealthPotion extends BaseItem {
  constructor(tile?, quantity?) {
    super();
    if (tile) {
      this.Tile = tile;
      this.Position = { x: tile.Start.x, y: tile.Start.y };
      FactionManager.NeutralFaction.AddEntity(this);
    }
    if (quantity) {
      this.Quantity = quantity;
    }
    this.Flipped = true;
  }
  public Entity: Entity | null;
  public Tile: Tile | null;
  public Position: Vector2D | null;
  public Faction: Faction | null;
  private _sprite: any;
  public Name = 'Health Potion';
  public UI: any;
  private _healing: number = 5;
  public Rarity: any;
  public Blocking: false = false;
  public DrawComponent: any;
  public Quantity: number | null = 1;
  public Flipped: true;
  public Slot: number | null;
  public Description = `restores ${this._healing} health`;
  public _damage: number = 0;
  public _armor: number = 0;
  public _mp: number = 0;
  public _range: number = 0;
  public _maxHealth: number = 0;

  public Awake(): void {
    if (this.Position) {
      this.AddDrawComponent();
    }
    super.Awake();
  }

  public get Sprite(): any {
    if (!this._sprite) {
      const spritePosition = [5, 2];
      const texture = new PIXI.Texture(PIXI.utils.TextureCache['artifacts']);
      const rectangle = new PIXI.Rectangle(
        spritePosition[0] * 16,
        spritePosition[1] * 16,
        16,
        16
      );
      texture.frame = rectangle;
      this._sprite = new PIXI.Sprite(texture);
    }
    return this._sprite;
  }

  public Update(deltaTime: number): void {
    super.Update(deltaTime);
  }

  public Use(): void {
    if (TWEEN.getAll().length > 0) return;

    if (FactionManager.Player.ViewMode == true) return;

    if (this.Entity instanceof Inventory) {
      const owner = this.Entity.Owner;
      if (owner.MaxHealth == owner.Health) {
        return;
      }
      if (owner.MovementPoints < 1) return;
      owner.Health += this._healing;
      if (owner.Health > owner.MaxHealth) {
        owner.Health = owner.MaxHealth;
      }
      this.Quantity -= 1;
      this.UI;

      Game.Log.Add(
        `You drank ${this.Name.toLocaleLowerCase()} +${this._healing}❤`
      );
      owner.MovementPoints -= 1;
      owner.Moved = false;

      if (this.Quantity < 1) {
        const playerUI = FactionManager.Player.Inventory.InvUi._invUI;
        playerUI.removeChild(this.UI._itemUI);
        playerUI.removeChild(this.UI._quantityUI);
        this.Entity.RemoveItem(this);
      }
      this.Update(performance.now());
    }
  }

  public Damage(): number {
    return 0;
  }

  public Armor(): number {
    return 0;
  }

  public AddDrawComponent(): void {
    if (!this.DrawComponent) {
      this.DrawComponent = new ItemDrawComponent();
      this.AddComponent(this.DrawComponent);
    }
  }
  public turn(): void {}
  public refresh(): void {}
  public over(): void {}
}
