import { Entity, IComponent, Vector2D } from '@/utils';
import { Tile } from '@/tile';
import { BaseItem } from '@/items/baseItem';
import { Faction, FactionManager } from '@/faction';
import * as PIXI from 'pixi.js';
import { ItemDrawComponent } from '@/items/draw/draw';

export class BunnyMask extends BaseItem {
  constructor(tile?) {
    super();
    if (tile) {
      this.Tile = tile;
      this.Position = { x: tile.Start.x, y: tile.Start.y };
      FactionManager.NeutralFaction.AddEntity(this);
    }
  }
  public Entity: Entity | null;
  public Tile: Tile | null;
  public Position: Vector2D | null;
  public Faction: Faction | null;
  private _sprite: any;
  public Name = 'bunny mask';

  public UI: any;
  public _damage: number = 0;
  public _armor: number = 0;
  public _mp: number = 0;
  public _range: number = 0;
  public _maxHealth: number = 0;
  public Rarity: any;
  public Blocking: false = false;
  public DrawComponent: any;
  public Description = `+ Max 🐾 every right level`;

  public _cDamage = 0;
  public _cArmor = 0;
  public _cMp = 0;
  public _cRange = 0;
  public _cMaxHealth = 0;

  public Awake(): void {
    if (this.Position) {
      this.AddDrawComponent();
    }
    super.Awake();
  }

  public get Sprite(): any {
    if (!this._sprite) {
      const spritePosition = [4, 0];
      const texture = new PIXI.Texture(PIXI.utils.TextureCache['instruments']);
      const rectangle = new PIXI.Rectangle(
        spritePosition[0] * 16,
        spritePosition[1] * 16,
        16,
        16
      );
      texture.frame = rectangle;
      this._sprite = new PIXI.Sprite(texture);
    }
    return this._sprite;
  }

  public Update(deltaTime: number): void {
    this._cMp = FactionManager.Player.right;
    this.Description = `+ Max 🐾 every right level`;
  }

  public Damage(): number {
    return this._damage;
  }
  public Use(): any {}
  public Armor(): number {
    return 0;
  }

  public AddDrawComponent(): void {
    if (!this.DrawComponent) {
      this.DrawComponent = new ItemDrawComponent();
      this.AddComponent(this.DrawComponent);
    }
  }

  public turn(): void {}
  public refresh(): void {}
  public over(): void {}
}
