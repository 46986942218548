import { arrowEffect, Entity, IComponent, Vector2D } from '@/utils';
import { Player } from '@/player';
import * as TWEEN from '@tweenjs/tween.js';
import { Enemy } from '@/enemy/enemy';
import { FactionManager } from '@/faction';
import { Game } from '@/game';
import { Settings } from '@/settings';
import { BaseItem } from '@/items';
import { LevelManager } from '@/levelManager';
import { CanvasLayer } from '@/canvasLayer';
import {tm} from "@/turnManager";

export class PlayerCombatComponent implements IComponent {
  public Entity: Player | Enemy;


  public Awake(): void {
    /* @todo */
  }

  public Attack(): number {
    return this.Entity.BaseDamage + this.Entity._cDamage
  }

  public Armor(): number {
    return this.Entity.BaseArmor + this.Entity._cArmor
  }

  public AttackEntity(victim: PlayerCombatComponent) {
    const thisName = this.Entity.Name;
    const victimName = victim.Entity.Name;
    let damage = this.Attack() - victim.Armor();
    if (damage < 0) {
      damage = 0;
    }
    Game.Log.Add(`${thisName} hit ${victimName} for ${damage}❤`);
    this.tweenAttack(victim);
    victim.Entity.Health -= damage;
    if (victim.Entity.Health < 1) {
      if (victim.Entity instanceof Player) {
        Game.Log.Add('you died');
        Game.Log.Add('refresh the page to restart!');
        victim.Entity.MovementPoints = 0
        victim.Entity.Moved = true
        return

      } else {
        FactionManager.EnemyFaction.RemoveEntity(victim.Entity);
        Game.Log.Add(`${victim.Entity.Name} died`);
      }
    }
  }
  public tweenAttack(victim: PlayerCombatComponent): void {
    const realStart = { x: this.Entity.Start.x, y: this.Entity.Start.y };
    if (this.Entity.Start && this.Entity.Start.x > -1 && this.Entity.Tile) {
      new TWEEN.Tween(this.Entity.Start)
        .to(victim.Entity.Start, Settings.tweenDuration)
        .easing(TWEEN.Easing.Quadratic.Out)
        .repeat(1)
        .yoyo(true)
        .start()
        .onComplete(
          (): void => {
            this.Entity.Moved = false;
            this.Entity.MovementPoints -= 1;
          }
        );
    }
  }

  public RangeAttackEntity(projectile: BaseItem, victim: Player | Enemy) {
    if (this.Entity.Range+this.Entity._cRange < this.Entity.ViewTileDistance) {
      Game.Log.Add(`${victim.Name} is out of range`);
      return;
    }
    const projectileSprite = arrowEffect();

    projectileSprite.setTransform(
      this.Entity.Position.x,
      this.Entity.Position.y,
      0.75,
      0.75,
      -0.8
    );
    // projectileSprite.anchor.set(0.5, 0.5);

    CanvasLayer.Foreground.addChild(projectileSprite);
    const realStart = { x: this.Entity.Position.x, y: this.Entity.Position.y };
    if (this.Entity.Start && this.Entity.Start.x > -1 && this.Entity.Tile) {
      new TWEEN.Tween(projectileSprite.position)
        .to(victim.Position, Settings.tweenDuration)
        .easing(TWEEN.Easing.Quadratic.In)
        .start()
        .onComplete(
          (): void => {
            victim.CombatComponent.Entity.Health -= projectile.rangedDamage;
            Game.Log.Add(
              `You shot ${victim.Name.toLocaleLowerCase()} for ${
                projectile.rangedDamage
              }❤`
            );

            if (victim.CombatComponent.Entity.Health < 1) {
              if (victim.CombatComponent.Entity instanceof Player) {
              } else {
                FactionManager.EnemyFaction.RemoveEntity(
                  victim.CombatComponent.Entity
                );
                Game.Log.Add(`${victim.Name} died`);
              }
            }

            CanvasLayer.Foreground.removeChild(projectileSprite);
            projectileSprite.destroy();
            projectile.Quantity -= 1;
            projectile.UI.Update();
            if (projectile.Quantity < 1) {
              this.Entity.Inventory.RemoveItem(projectile);
            }
            if (this.Entity instanceof Player) {
              this.Entity.ToggleView();
            }

            this.Entity.Moved = false;
            this.Entity.MovementPoints -= 1;
          }
        );
    }
  }

  public Update(deltaTime: number): void {
    /* @todo */
  }
}
