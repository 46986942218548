import { Faction } from '@/faction/faction';
import { Entity } from '@/utils';
import { Player } from '@/player';
import { LevelManager } from '@/levelManager';

export class FactionManager {
  private static _friendlyFaction: Faction;
  private static _enemyFaction: Faction;
  private static _neutralFaction: Faction;
  private static _player: Player;

  private constructor() {
    /* inaccessible */
  }

  public static get FriendlyFaction(): Faction {
    if (!this._friendlyFaction) {
      this._friendlyFaction = new Faction();
    }

    return this._friendlyFaction;
  }

  public static get Player(): Player {
    if (!this._friendlyFaction) {
      this._friendlyFaction = new Faction();
    }
    if (!this._player) {
      this._player = this._friendlyFaction.Player;
    }

    return this._player;
  }

  public static get EnemyFaction(): Faction {
    if (!this._enemyFaction) {
      this._enemyFaction = new Faction();
    }

    return this._enemyFaction;
  }

  public static get NeutralFaction(): Faction {
    if (!this._neutralFaction) {
      this._neutralFaction = new Faction();
    }

    return this._neutralFaction;
  }
  public static get AllActors(): Entity[] {
    let allActors = [
      ...this._friendlyFaction.Entities,
      ...this._enemyFaction.Entities,
    ];
    if (this._neutralFaction && this._neutralFaction.Entities.size > 0) {
      allActors = [...allActors, ...this._neutralFaction.Entities];
    }
    return allActors;
  }

  public static get AllEnemies(): Entity[] {
    let allEnemies = [...this._enemyFaction.Entities];
    return allEnemies;
  }
}
