import { Entity, randomInt } from '@/utils';
import { Player } from '@/player';
import { FactionManager } from '@/faction/factionManager';
import { FactionUIComponent } from '@/faction/draw';
import { tm } from '@/turnManager';
import { CanvasLayer } from '@/canvasLayer';
import { EnemyDrawComponent, EnemyUIComponent } from '@/enemy/draw';
import { Enemy } from '@/enemy/enemy';
import { GameLog } from '@/gameLog';
import { LevelManager } from '@/levelManager';
import { ItemDrawComponent } from '@/items/draw/draw';
import { LeatherBoots, WoodenShield, WoodenSword } from '@/items';
import * as TWEEN from '@tweenjs/tween.js';
import { Settings } from '@/settings';
import { Vector2D } from '@/utils';
import { Game } from '@/game';
import { SilverArrow } from '@/items/arrow/silverArrow';
import { DivinePotion } from '@/items/consumable/divinePotion';
import { SilverSword } from '@/items/sword/silverSword';
import { SilverBow } from '@/items/silverBow';
import { BlackFlag } from '@/items/blackFlag';
import { BunnyMask } from '@/items/bunnyMask';
import { Drums } from '@/items/drums';
import { Faerie } from '@/items/faerie';
import { Guitar } from '@/items/guitar';
import { Harp } from '@/items/harp';
import { ImpMask } from '@/items/impMask';
import { Ocarina } from '@/items/ocarina';
import { SteelShield } from '@/items/steelShield';
import { SteelBoots } from '@/items/steelBoots';
import { StoneMask } from '@/items/stoneMask';
import { Crystal } from '@/items/crystal';
import { WoodenBow } from '@/items/woodenBow';

export class Faction extends Entity {
  private _entities: Set<Entity> = new Set();
  public Player: Player;

  public Awake(): void {
    if (this === FactionManager.EnemyFaction) {
      if (!FactionManager.EnemyFaction.HasComponent(FactionUIComponent)) {
        FactionManager.EnemyFaction.AddComponent(new FactionUIComponent());
      }
    }

    for (const entity of this._entities) {
      entity.Awake();
    }
    super.Awake();
  }

  public Update(deltaTime: number): void {
    super.Update(deltaTime);

    this._entities.forEach(entity => entity.Update(deltaTime));
  }

  public get Entities(): Set<Entity> {
    return this._entities;
  }

  public AddEntity(newEntity: Entity): void {
    let duplicateCounter = 1;
    this._entities.forEach(entity => {
      if (entity.constructor.name == newEntity.constructor.name) {
        duplicateCounter += 1;
      }
    });
    if (duplicateCounter > 1) {
      if (newEntity instanceof Enemy) {
        newEntity.Name += ` ${duplicateCounter}`;
      }
    }
    this._entities.add(newEntity);
    if (newEntity instanceof Player) {
      this.Player = newEntity;
    }
  }

  public RemoveEntity(entity: Enemy | any): void {
    this._entities.delete(entity);
    tm.removeEntity(entity);
    const drawComponent = entity.GetComponent(EnemyDrawComponent);
    const uiComponent = entity.GetComponent(EnemyUIComponent);
    uiComponent.Name.style.fill = 0x2f4f4f;
    uiComponent.Stats.style.fill = 0x2f4f4f;
    // string is 8chars
    uiComponent.Stats.text = `--------------`;
    entity.Portrait.tint = 0x363131;
    CanvasLayer.Foreground.removeChild(entity._enemySprite);
    entity._enemySprite.destroy;
    if (FactionManager.AllEnemies.length == 0) {
      LevelManager.Active.Locked = false;
      LevelManager.Active.Doors.forEach(door => {
        door.Open();
      });

      let itemRandomTile = LevelManager.Active.randomOpenTile();
      let itemTempNode =
        itemRandomTile && LevelManager.Active.GetTileByIndex(itemRandomTile);

      let rewardItems = [
        SilverArrow,
        SilverArrow,
        SilverArrow,
        SilverArrow,
        SilverArrow,
        DivinePotion,
        SilverSword,
        SilverSword,
        SilverBow,
        BlackFlag,
        BlackFlag,
        BunnyMask,
        Drums,
        Faerie,
        Guitar,
        Guitar,
        Harp,
        ImpMask,
        ImpMask,
        Ocarina,
        Ocarina,
        SteelShield,
        SteelBoots,
        StoneMask,
        Crystal,
        LeatherBoots,
        WoodenBow,
        WoodenShield,
        WoodenSword,
        Crystal,
        LeatherBoots,
        WoodenBow,
        WoodenShield,
        WoodenSword,
        Crystal,
        LeatherBoots,
        WoodenShield,
        WoodenSword,
        WoodenSword,
        WoodenSword,
        WoodenSword,
        Crystal,
        LeatherBoots,
        WoodenShield,
        WoodenSword,
      ];

      let item = new rewardItems[(randomInt(45))](itemTempNode);
      LevelManager.Active._entites.push(item);
      item.Awake();
      item.Position = new Vector2D(50, 10);

      Game.Log.Add(`${item.Name} dropped!`);

      if (FactionManager.Player.Level == 10) {
        Game.Log.Add(`You won the game! Congratulations!`);
      }
    }
  }

  public ResetItems(): void {
    if (this === FactionManager.NeutralFaction) {
      this._entities.forEach(item => {
        if (item.HasComponent(ItemDrawComponent)) {
          let drawComponent = item.GetComponent(ItemDrawComponent);
          drawComponent.Entity.DrawComponent = null;

          CanvasLayer.Foreground.removeChild(drawComponent.Entity.Sprite);
          // drawComponent.Entity.Sprite = null
          drawComponent._itemSprite = null;
          // drawComponent._itemSprite.destroy();
        }
      });
      this._entities = new Set();
    }
  }
}
