import { IComponent, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { Faction } from '@/faction';
import { EnemyUIComponent } from '@/enemy/draw';

export class FactionUIComponent implements IComponent {
  public Entity: Faction;
  public _factionUI: any;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._factionUI = new PIXI.Graphics();
    this._factionUI.beginFill(0xa9a9a9);
    this._factionUI.drawRect(
      1,
      height / 11 + 1,
      width - 2,
      (height / 11) * 6.5 -11
    );
    this._factionUI.y = 2
    this._factionUI.endFill();

    // this._factionUI.filters = [new OutlineFilter(1, 0x00000)];
    for (let i = 0; i < this.Entity.Entities.size; i++) {
      const entity = [...this.Entity.Entities][i];
      if (entity.HasComponent(EnemyUIComponent)) {
        const uiComponent = entity.GetComponent(EnemyUIComponent);
        uiComponent._enemyUI.y = height / 11 + Settings.tileSize * i + 1;
        this._factionUI.addChild(uiComponent._enemyUI);
      }
    }

    this.Draw();
  }

  public Update(deltaTime: number): void {}

  public Draw(): void {
    CanvasLayer.RightUI.addChild(this._factionUI);
  }
}
