import { Level } from '@/level';
import { devRoom, devroom3, randomInt } from '@/utils';
import { devRoom2 } from '@/utils';
import * as Comlink from 'comlink';
import { wrap } from 'comlink';
import sound from 'pixi-sound';

export function randomLevel() {
  const worker = new Worker('../worker', {
    name: 'worker',
    type: 'module',
  });
  const workerApi = wrap<import('../worker').MyFirstWorker>(worker);
  return workerApi.randomLevel();
}

export class LevelManager {
  private static _active: Level;
  public static Levels: Level[] = [];
  public static TypeArray: string[] = ['forest', 'mountain', 'beach'];

  private constructor() {
    /* inaccessible */
  }

  public static get Active(): Level {
    if (!this._active) {
      for (let i = 0; i < 9; i++) {
        if (i == 4) {
          let newLevel = this.InitLevel(devRoom2);
          this.Levels.push(newLevel);
          this._active = newLevel;
        } else {
          (async () => {
            randomLevel().then((blueprint: any) => {
              let newLevel = this.InitLevel(blueprint);
              this.Levels.push(newLevel);
            });
          })();
        }
      }
    }

    return this._active;
  }

  public static set Active(newLevel: Level) {
    this._active = newLevel;
  }

  private static InitLevel(blueprint: any[]): Level {
    const levelType = this.TypeArray[randomInt(3)];
    const level = new Level(blueprint, levelType);

    return level;
  }
  public static Test(): void {
    sound.toggleMuteAll()
  }

  public static Regenerate(): void {
    for (let i = 0; i < 9; i++) {
      if (i == 4) {
        continue;
      } else {
        (async () => {
          randomLevel().then((blueprint: any) => {
            let newLevel = this.InitLevel(blueprint);
            this.Levels[i] = newLevel;
          });
        })();
      }
    }
  }
}
