import { Game } from '@/game';
import { IComponent, Vector2D, OnClickComponent } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Canvas } from '@/canvas';

export class GameInputComponent implements IComponent {
  public Entity: Game;

  public Awake(): void {
    Canvas.Application.renderer.plugins.interaction.on(
      'mousedown',
      (event: any) => {
        const point = new Vector2D(event.data.global.x, event.data.global.y);
        for (const entity of this.Entity.Entities) {
          if (!entity.HasComponent(OnClickComponent)) {
            continue;
          }

          entity.GetComponent(OnClickComponent).ClickOn(point); // <--- CHANGE
        }
      }
    );
  }

  public Update(deltaTime: number): void {
    // @todo
  }
}
