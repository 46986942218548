import { Entity, Vector2D } from '@/utils';
import { Tile } from '@/tile';
import { Settings } from '@/settings';
import { devRoom } from '@/utils';
import { randomInt } from '@/utils';
import { Player, PlayerLocomotionComponent } from '@/player';
import { FactionManager } from '@/faction';
import { LevelOnClickComponent } from '@/level/components';
import { LevelManager } from '@/levelManager';
import { ItemDrawComponent } from '@/items/draw/draw';
import {BaseItem, HealthPotion, LeatherBoots, WoodenArrow, WoodenShield, WoodenSword} from '@/items';
import { Enemy } from '@/enemy/enemy';
import { tm } from '@/turnManager';
import { Door } from '@/door';
import { CanvasLayer } from '@/canvasLayer';
import { Skeleton } from '@/enemy/skeleton';
import {
  Shrub,
  Stonefolk,
  Goblin,
  GoblinRider,
  Ogre,
  Ghost,
  DarkRider,
  ElderShrub,
  Penguin,
  Nightmare,
  Dwarf,
  Spider,
} from '@/enemy';
import { Finfolk } from '@/enemy/finfolk';
import { Lion, Naga } from '@/enemy';

import { Game } from '@/game';
import {BunnyMask} from "@/items/bunnyMask";
import {Ocarina} from "@/items/ocarina";
import {SilverArrow} from "@/items/arrow/silverArrow";
import {WoodenBow} from "@/items/woodenBow";
import {BlackFlag} from "@/items/blackFlag";

export class Level extends Entity {
  public _entites: Entity[] = [];
  public Msgs: string[] = [];
  public Tiles: Tile[] = [];
  public isActive: boolean = false;
  public isComplete: boolean = false;
  public Locked: boolean = true;
  public Doors: Door[] = [];

  public constructor(public blueprint: any[], public Type: string) {
    super();
    this.InitTiles();
  }

  public Awake(): void {
    this.AddComponent(new LevelOnClickComponent());

    for (const tile of this.Tiles) {
      tile.Awake();
    }
    for (const entity of this._entites) {
      entity.Awake();
    }
  }

  public GetTileByIndex(index: any): any {
    for (let i = 0; i < this.Tiles.length; i++) {
      if (
        this.Tiles[i].Index.x === index.x &&
        this.Tiles[i].Index.y === index.y
      ) {
        return this.Tiles[i];
      }
    }
  }

  private InitTiles(): void {
    if (this.Tiles.length > 0) {
      return;
    }
    const size = Settings.tileSize;
    const floor = this.blueprint;
    for (let y = 0; y < floor.length; y++) {
      for (let x = 0; x < floor[y].length; x++) {
        const start = new Vector2D(x * size, y * size);
        const type = floor[y][x] == 1 ? 'wall' : 'floor';
        const end = new Vector2D(start.x + size, start.y + size);
        const index = new Vector2D(x, y);
        const tile = new Tile(start, end, index, type);

        this.Tiles.push(tile);
      }
    }
  }

  public InitStuff(): void {
    let doorTiles = this.getDoorTiles();

    doorTiles.up.forEach(tile => {
      let door = new Door('up', tile);
      FactionManager.NeutralFaction.AddEntity(door);
      this._entites.push(door);
      this.Doors.push(door);
    });

    doorTiles.right.forEach(tile => {
      let door = new Door('right', tile);
      FactionManager.NeutralFaction.AddEntity(door);
      this._entites.push(door);
      this.Doors.push(door);
    });

    doorTiles.down.forEach(tile => {
      let door = new Door('down', tile);
      FactionManager.NeutralFaction.AddEntity(door);
      this._entites.push(door);
      this.Doors.push(door);
    });

    doorTiles.left.forEach(tile => {
      let door = new Door('left', tile);
      FactionManager.NeutralFaction.AddEntity(door);
      this._entites.push(door);
      this.Doors.push(door);
    });
    if (FactionManager.Player.Level == 10) {
      if (LevelManager.Active.Type == 'forest') {
        let enemyRandomTile = LevelManager.Active.randomOpenTile();
        let enemyTempNode =
          enemyRandomTile &&
          LevelManager.Active.GetTileByIndex(enemyRandomTile);
        new ElderShrub(FactionManager.EnemyFaction, enemyTempNode);
        let enemyRandomTile2 = LevelManager.Active.randomOpenTile();
        let enemyTempNode2 =
          enemyRandomTile2 &&
          LevelManager.Active.GetTileByIndex(enemyRandomTile2);
        new ElderShrub(FactionManager.EnemyFaction, enemyTempNode2);
        for (let j = 0; j < 10; j++) {
          let enemyRandomTile3 = LevelManager.Active.randomOpenTile();
          let enemyTempNode3 =
            enemyRandomTile3 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile3);
          new Shrub(FactionManager.EnemyFaction, enemyTempNode3);
        }
      }

      if (LevelManager.Active.Type == 'mountain') {
        let enemyRandomTile2 = LevelManager.Active.randomOpenTile();
        let enemyTempNode2 =
          enemyRandomTile2 &&
          LevelManager.Active.GetTileByIndex(enemyRandomTile2);
        new DarkRider(FactionManager.EnemyFaction, enemyTempNode2);
        for (let j = 0; j < 3; j++) {
          let enemyRandomTile3 = LevelManager.Active.randomOpenTile();
          let enemyTempNode3 =
            enemyRandomTile3 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile3);
          new Ghost(FactionManager.EnemyFaction, enemyTempNode3);
        }
        for (let k = 0; k < 3; k++) {
          let enemyRandomTile4 = LevelManager.Active.randomOpenTile();
          let enemyTempNode4 =
            enemyRandomTile4 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile4);
          new Skeleton(FactionManager.EnemyFaction, enemyTempNode4);
        }
        for (let l = 0; l < 2; l++) {
          let enemyRandomTile4 = LevelManager.Active.randomOpenTile();
          let enemyTempNode4 =
            enemyRandomTile4 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile4);
          new Nightmare(FactionManager.EnemyFaction, enemyTempNode4);
        }
      }
      if (LevelManager.Active.Type == 'beach') {
        for (let j = 0; j < 2; j++) {
          let enemyRandomTile3 = LevelManager.Active.randomOpenTile();
          let enemyTempNode3 =
            enemyRandomTile3 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile3);
          new Lion(FactionManager.EnemyFaction, enemyTempNode3);
        }
        for (let j = 0; j < 2; j++) {
          let enemyRandomTile3 = LevelManager.Active.randomOpenTile();
          let enemyTempNode3 =
            enemyRandomTile3 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile3);
          new Finfolk(FactionManager.EnemyFaction, enemyTempNode3);
        }
        for (let j = 0; j < 2; j++) {
          let enemyRandomTile3 = LevelManager.Active.randomOpenTile();
          let enemyTempNode3 =
            enemyRandomTile3 &&
            LevelManager.Active.GetTileByIndex(enemyRandomTile3);
          new Naga(FactionManager.EnemyFaction, enemyTempNode3);
        }
      }
    } else {
      for (let i = 0; i < FactionManager.Player.Level + 2; i++) {
        let enemyRandomTile = LevelManager.Active.randomOpenTile();
        let enemyTempNode =
          enemyRandomTile &&
          LevelManager.Active.GetTileByIndex(enemyRandomTile);

        let enemyArray = [
          Penguin,
          Skeleton,
          Shrub,
          Goblin,
          Spider,
          Ghost,
          Naga,
          GoblinRider,
          Dwarf,
          Nightmare,
          Finfolk,
          Stonefolk,
          Ogre,
        ];
        let enemy = enemyArray[randomInt(4 + i)];
        new enemy(FactionManager.EnemyFaction, enemyTempNode);
      }
    }
    let itemRandomTile = LevelManager.Active.randomOpenTile();
    let itemTempNode =
      itemRandomTile && LevelManager.Active.GetTileByIndex(itemRandomTile);

    let itemRandomTile3 = LevelManager.Active.randomOpenTile();
    let itemTempNode3 =
      itemRandomTile3 && LevelManager.Active.GetTileByIndex(itemRandomTile3);

    let item3 = new WoodenArrow(itemTempNode3);
    this._entites.push(item3);


    let itemRandomTile8 = LevelManager.Active.randomOpenTile();
    let itemTempNode8 =
      itemRandomTile8 && LevelManager.Active.GetTileByIndex(itemRandomTile8);

    let item8 = new HealthPotion(itemTempNode8);
    this._entites.push(item8);

  }

  // public Update(deltaTime: number): void {
  //   super.Update(deltaTime);
  //
  //   for (const tile of this.Tiles) {
  //     tile.Update();
  //   }
  // }

  public isWalkableTile(index: Vector2D): Tile | boolean {
    const tileAtDestination = this.GetTileByIndex(index);

    if (tileAtDestination.Type === 'floor') {
      const entitiesAtTile = this.getEntitiesAtTile(index);
      if (entitiesAtTile == false) {
        return tileAtDestination;
      }

      if (
        !entitiesAtTile.some((entity: Entity) => {
          return entity.Blocking == true;
        })
      ) {
        return tileAtDestination;
      }
    }
    return false;
  }

  public isOpenTile(index: Vector2D): Tile | boolean {
    const tileAtDestination = this.GetTileByIndex(index);

    if (tileAtDestination.Type === 'floor') {
      const entitiesAtTile = this.getEntitiesAtTile(index);
      if (entitiesAtTile == false) {
        return tileAtDestination;
      }
    }
    return false;
  }

  public randomOpenTile(): Vector2D {
    let x = randomInt(Settings.grid.width - 1);
    let y = randomInt(Settings.grid.height - 1);
    let tileAtDestination = this.GetTileByIndex(new Vector2D(x, y));
    while (this.isOpenTile(tileAtDestination.Index) == false) {
      x = randomInt(Settings.grid.width - 1);
      y = randomInt(Settings.grid.height - 1);
      tileAtDestination = this.GetTileByIndex(new Vector2D(x, y));
    }
    return new Vector2D(x, y);
  }

  public randomWalkableTile(): Vector2D {
    let x = randomInt(Settings.grid.width - 1);
    let y = randomInt(Settings.grid.height - 1);
    let tileAtDestination = this.GetTileByIndex(new Vector2D(x, y));
    while (this.isWalkableTile(tileAtDestination.Index) == false) {
      x = randomInt(Settings.grid.width - 1);
      y = randomInt(Settings.grid.height - 1);
      tileAtDestination = this.GetTileByIndex(new Vector2D(x, y));
    }
    return new Vector2D(x, y);
  }

  public getEntitiesAtTile(index: Vector2D): Entity[] | false {
    let entities = [];
    let allActors = FactionManager.AllActors;
    for (let i = 0; i < allActors.length; i++) {
      let e = allActors[i];

      if (
        e.HasComponent(PlayerLocomotionComponent) &&
        e.GetComponent(PlayerLocomotionComponent).Index.x == index.x &&
        e.GetComponent(PlayerLocomotionComponent).Index.y == index.y
      ) {
        entities.push(e);
      }
      if (
        e instanceof BaseItem &&
        e.Tile &&
        e.Tile.Index.x == index.x &&
        e.Tile.Index.y == index.y
      ) {
        entities.push(e);
      }
      if (
        e instanceof Door &&
        e.Tile &&
        e.Tile.Index.x == index.x &&
        e.Tile.Index.y == index.y
      ) {
        entities.push(e);
      }
    }
    if (entities.length < 1) return false;
    return entities;
  }

  public getActorsAtTile(index: Vector2D): Enemy | Player[] | false {
    let entities = [];
    let allEnemies = FactionManager.AllEnemies;
    for (let i = 0; i < allEnemies.length; i++) {
      let e = allEnemies[i];

      if (
        e.HasComponent(PlayerLocomotionComponent) &&
        e.GetComponent(PlayerLocomotionComponent).Index.x == index.x &&
        e.GetComponent(PlayerLocomotionComponent).Index.y == index.y
      ) {
        entities.push(e);
      }
    }
    if (entities.length < 1) return false;
    return entities;
  }

  public getDoorTiles(): any {
    let doorTiles = { left: [], up: [], right: [], down: [] };

    for (let i = 0; i < this.Tiles.length; i++) {
      let tile = this.Tiles[i];
      if (tile.Index.y == 0 && this.isWalkableTile(tile.Index)) {
        doorTiles.up.push(tile);
      }
      if (tile.Index.x == 0 && this.isWalkableTile(tile.Index)) {
        doorTiles.left.push(tile);
      }
      if (
        tile.Index.x == Settings.grid.width - 1 &&
        this.isWalkableTile(tile.Index)
      ) {
        doorTiles.right.push(tile);
      }
      if (
        tile.Index.y == Settings.grid.height - 1 &&
        this.isWalkableTile(tile.Index)
      ) {
        doorTiles.down.push(tile);
      }
    }

    return doorTiles;
  }
  public resetLevel() {
    this.Tiles.forEach(tile => {
      CanvasLayer.Foreground.removeChild(tile.DrawComponent._backgroundSprite);
      tile.DrawComponent._backgroundSprite.destroy;
      tile.DrawComponent = null;
    });
    CanvasLayer.Background.removeChildren();
    // this.Tiles = [];
    // FactionManager.NeutralFaction.ResetItems();
  }
}
