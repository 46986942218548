import { IComponent, KeyboardManager, IKey, Entity } from '@/utils';
import { Player } from '@/player';
import { Tile } from '@/tile';
import { PlayerLocomotionComponent } from '@/player';
import { LevelManager } from '@/levelManager';

export class PlayerKeyInputComponent implements IComponent {
  public Entity: Player;

  private _node: Tile | null = null;

  public Awake(): void {
    const playerLocomotion = this.Entity.GetComponent(
      PlayerLocomotionComponent
    );
    const player = this.Entity;

    const leftUp = KeyboardManager('q');
    const up = KeyboardManager('w');
    const rightUp = KeyboardManager('e');
    const right = KeyboardManager('d');
    const downRight = KeyboardManager('c');
    const down = KeyboardManager('x');
    const downLeft = KeyboardManager('z');
    const left = KeyboardManager('a');

    const take = KeyboardManager('t');
    const stay = KeyboardManager('s');
    const view = KeyboardManager('v');

    const one = KeyboardManager('1');
    const two = KeyboardManager('2');
    const three = KeyboardManager('3');
    const four = KeyboardManager('4');
    const five = KeyboardManager('5');
    const six = KeyboardManager('6');
    const seven = KeyboardManager('7');
    const eight = KeyboardManager('8');
    const nine = KeyboardManager('9');
    const m = KeyboardManager('m');
    const drop = KeyboardManager('r');
    const back = KeyboardManager('b');

    leftUp.press = function(): void {
      playerLocomotion.MoveUpLeft();
    };
    up.press = function(): void {
      playerLocomotion.MoveUp();
    };
    rightUp.press = function(): void {
      playerLocomotion.MoveUpRight();
    };

    right.press = function(): void {
      playerLocomotion.MoveRight();
    };

    downRight.press = function(): void {
      playerLocomotion.MoveDownRight();
    };

    down.press = function(): void {
      playerLocomotion.MoveDown();
    };

    downLeft.press = function(): void {
      playerLocomotion.MoveDownLeft();
    };

    left.press = function(): void {
      playerLocomotion.MoveLeft();
    };

    stay.press = function(): void {
      playerLocomotion.Stay();
    };

    take.press = function(): void {
      player.Take();
    };

    one.press = function(): void {
      player.UseItem(1);
    };
    two.press = function(): void {
      player.UseItem(2);
    };
    three.press = function(): void {
      player.UseItem(3);
    };
    four.press = function(): void {
      player.UseItem(4);
    };
    five.press = function(): void {
      player.UseItem(5);
    };
    six.press = function(): void {
      player.UseItem(6);
    };
    seven.press = function(): void {
      player.UseItem(7);
    };
    eight.press = function(): void {
      player.UseItem(8);
    };
    nine.press = function(): void {
      player.UseItem(9);
    };
    view.press = function(): void {
      player.ToggleView();
    };
    m.press = function(): void {
      LevelManager.Test();
    };
    drop.press = function(): void {
      player.ToggleDrop();
    };
    back.press = function(): void {
      player.Back();
    };
  }

  public Update(deltaTime: number): void {
    /* @todo */
  }
}
