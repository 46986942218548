export const Settings = Object.freeze({
  scale: 2,
  tileSize: 16,
  tileOffset: 0,
  tweenDuration: 200,
  grid: {
    width: 23,
    height: 23,
    color: { normal: 0xededed, active: 0x360ccc },
  },
  ui: {
    width: 8.5,
    font: '"Lucida Console", Monaco, monospace',
  },
});
