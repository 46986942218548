import { IComponent, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';

export class EnemyDrawComponent implements IComponent {
  public Entity: Player | any;
  public _enemySprite: any;
  public Outline: any
  private get Position(): Vector2D {
    const position = this.Entity.Position;
    if (!position) {
      throw new Error('Attempt to draw a monster that has no Position');
    }

    return position;
  }

  public Awake(): void {
    this.Entity._enemySprite.x = this.Entity.Start.x - 0.5;
    this.Entity._enemySprite.y = this.Entity.Start.y - 1;
    this.Draw();
  }

  public Update(deltaTime: number): void {
    this.Entity._enemySprite.x = this.Entity.Start.x-0.5;
    this.Entity._enemySprite.y = this.Entity.Start.y-1;

    this.Entity.Outline.color = this.Entity.Active ? 0x50331b : 0x000000;


  }

  private Draw(): void {
    CanvasLayer.Foreground.addChild(this.Entity._enemySprite);
  }
}
