import { IComponent, randomInt, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { OutlineFilter } from '@pixi/filter-outline';
import { Door } from '@/door';
import * as PIXI from 'pixi.js';
import { LevelManager } from '@/levelManager';

export class DoorDrawComponent implements IComponent {
  public Entity: Door;
  public _closedDoorSprite: any;
  public _openDoorSprite: any;
  public activeSprite: any;

  private get Position(): Vector2D {
    const position = this.Entity.Position;
    if (!position) {
      throw new Error('Attempt to draw an item that has no Position');
    }

    return position;
  }

  public Awake(): void {
    this._closedDoorSprite = this.makeClosedSprite();
    this._openDoorSprite = this.makeOpenSprite();

    this.activeSprite = this._closedDoorSprite;

    this._closedDoorSprite.x = this.Position.x;
    this._closedDoorSprite.y = this.Position.y;
    this._openDoorSprite.x = this.Position.x;
    this._openDoorSprite.y = this.Position.y;
    this.Draw();
  }

  public Update(deltaTime: number): void {}

  private Draw(): void {
    CanvasLayer.Foreground.addChild(this._closedDoorSprite);
  }

  public Open(): void {
    CanvasLayer.Foreground.removeChild(this._closedDoorSprite);
    CanvasLayer.Foreground.addChild(this._openDoorSprite);
  }
  private makeOpenSprite(): PIXI.Sprite {
    const spritePosition = [8, 23];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['dungeonTiles']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    const openSprite = new PIXI.Sprite(texture);
    openSprite.x = this.Entity.Tile.Index.x;
    openSprite.y = this.Entity.Tile.Index.y;
    openSprite.zIndex = 0

    return openSprite;
  }

  private makeClosedSprite(): PIXI.Sprite {
    // if (this.Entity.Direction)
    const spritePosition = [7, 23];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['dungeonTiles']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    const closedSprite = new PIXI.Sprite(texture);
    closedSprite.x = this.Entity.Tile.Index.x;
    closedSprite.y = this.Entity.Tile.Index.y;
    closedSprite.zIndex = 0

    return closedSprite;
  }
}
