import { Vector2D } from '@/utils';
import * as PIXI from 'pixi.js';
import { Settings } from '@/settings';

export class Canvas {
  private _elm: PIXI.Application;
  private _ctx: CanvasRenderingContext2D;
  public static Application: PIXI.Application;

  public get Element(): PIXI.Application {
    return this._elm;
  }

  public get Context(): CanvasRenderingContext2D {
    return this._ctx;
  }

  constructor() {
    const width = Settings.grid.width + (Settings.ui.width * 2);
    const height = Settings.grid.height;

    this._elm = new PIXI.Application({
      antialias: true,
      width: width * Settings.tileSize * Settings.scale,
      height: height * Settings.tileSize * Settings.scale,
      backgroundColor: 0xffffff,
      // forceCanvas: true,
      transparent: true,
    });

    this._elm.stage.scale = new PIXI.ObservablePoint(
      null,
      null,
      Settings.scale,
      Settings.scale
    );

    this._elm.stage.sortableChildren = true


    Canvas.Application = this._elm;
  }

  public Awake(): void {
    document.body.appendChild(this._elm.view);
  }

  public FillTile(start: Vector2D, sprite: PIXI.Sprite): void {
    sprite.x = start.x;
    sprite.y = start.y;

    this._elm.stage.addChild(sprite);
  }

  public SetStyle(style: Partial<CSSStyleDeclaration>): void {
    for (const key in style) {
      if (!Object.hasOwnProperty.call(style, key)) {
        continue;
      }

      if (!style[key]) {
        continue;
      }
      this._elm.view.style[key] = style[key] as string;
    }
  }
}
