import { Vector2D } from '@/utils';
import { Canvas } from '@/canvas';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';

export class CanvasLayer {
  private static _background: PIXI.Container;
  private static _foreground: PIXI.Container;
  private static _rightUI: PIXI.Container;
  private static _leftUI: PIXI.Container;

  private constructor() {
    /* inaccessible */
  }

  public static get Background(): PIXI.Container {
    if (!this._background) {
      this._background = this.InitCanvas();
    }

    return this._background;
  }

  public static get Foreground(): PIXI.Container {
    if (!this._foreground) {
      this._foreground = this.InitCanvas();
    }
    return this._foreground;
  }

  public static get RightUI(): PIXI.Container {
    if (!this._rightUI) {
      this._rightUI = this.InitRightUI();
    }
    return this._rightUI;
  }

  public static get LeftUI(): PIXI.Container {
    if (!this._leftUI) {
      this._leftUI = this.InitLeftUI();
    }
    return this._leftUI;
  }

  private static InitCanvas(): PIXI.Container {
    if (!Canvas.Application) {
      const canvas = new Canvas();
      canvas.Awake();
    }

    const width = Settings.grid.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    let container = new PIXI.Container();
    container.width = width;
    container.height = height;
    container.x = Settings.ui.width * Settings.tileSize;
    container.zIndex = 1;
    container.sortableChildren = true;
    Canvas.Application.stage.addChild(container);
    if (this._rightUI == null) {
      this._rightUI = this.InitRightUI();
    }
    if (this._leftUI == null) {
      this._leftUI = this.InitLeftUI();
    }

    return container;
  }

  private static InitRightUI(): PIXI.Container {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    // console.gameLog(width);

    let container = new PIXI.Container();
    // let container = new PIXI.Container();
    container.width = width;
    container.height = height;
    container.zIndex = 1;

    container.x = (Settings.grid.width + Settings.ui.width) * Settings.tileSize;
    // container.start

    const rightUI = new PIXI.Graphics();
    rightUI.beginFill(0x353935);
    rightUI.drawRect(0, 0, width, height);
    rightUI.endFill();
    container.addChild(rightUI);
    Canvas.Application.stage.addChild(container);

    return container;
  }

  private static InitLeftUI(): PIXI.Container {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;

    let container = new PIXI.Container();
    // let container = new PIXI.Container();
    container.width = width;
    container.height = height;
    container.zIndex = 1;

    container.sortableChildren = true;

    container.x = 0;
    const rightUI = new PIXI.Graphics();
    rightUI.beginFill(0x353935);
    rightUI.drawRect(0, 0, width, height);
    rightUI.endFill();
    container.addChild(rightUI);
    Canvas.Application.stage.addChild(container);
    return container;
  }
}
