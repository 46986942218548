import { Entity, IComponent, Vector2D } from '@/utils';
import { Tile } from '@/tile';
import { BaseItem } from '@/items/baseItem';
import { Faction, FactionManager } from '@/faction';
import * as PIXI from 'pixi.js';
import { ItemDrawComponent } from '@/items/draw/draw';
import { Inventory } from '@/inventory';
import { Game } from '@/game';
import * as TWEEN from '@tweenjs/tween.js';
import { Player } from '@/player';
import { LevelManager } from '@/levelManager';
import { Enemy } from '@/enemy/enemy';
export class SilverArrow extends BaseItem {
  constructor(tile?, quantity?) {
    super();
    if (tile) {
      this.Tile = tile;
      this.Position = { x: tile.Start.x, y: tile.Start.y };
      FactionManager.NeutralFaction.AddEntity(this);
    }
    if (quantity) {
      this.Quantity = quantity;
    }
    this.Flipped = false;
  }
  public Entity: Entity | null;
  public Tile: Tile | null;
  public Position: Vector2D | null;
  public Faction: Faction | null;
  private _sprite: any;
  public Name = 'silver arrow';

  public UI: any;
  public Rarity: any;
  public Blocking: false = false;
  public DrawComponent: any;
  public Quantity: number | null = 5;
  public Flipped: false;
  public Slot: number | null;
  public _damage: number = 0;
  public _armor: number = 0;
  public _mp: number = 0;
  public _range: number = 0;
  public _maxHealth: number = 0;
  public rangedDamage: number = 5;
  public Projectile: boolean = true;
  public Description = `ranged damage: ${this.rangedDamage +
    FactionManager.Player._cRange}     range:${FactionManager.Player.Range}`;

  public Awake(): void {
    if (this.Position) {
      this.DrawComponent = new ItemDrawComponent();
      this.AddComponent(this.DrawComponent);
    }
    super.Awake();
  }

  public get Sprite(): any {
    if (!this._sprite) {
      const spritePosition = [1, 0];
      const texture = new PIXI.Texture(PIXI.utils.TextureCache['arrows']);
      const rectangle = new PIXI.Rectangle(
        spritePosition[0] * 16 + 1,
        spritePosition[1] * 16,
        14,
        14
      );
      texture.frame = rectangle;

      this._sprite = new PIXI.Sprite(texture);
      this._sprite.anchor.set(-0.025, -0.05);
    }
    return this._sprite;
  }

  public Update(deltaTime: number): void {
    this.Description = `ranged damage: ${
      this.rangedDamage
    }     range:${FactionManager.Player.Range + FactionManager.Player._cRange}`;
  }

  public Use(): void {
    if (TWEEN.getAll().length > 0) return;

    if (this.Entity instanceof Inventory) {
      const owner = this.Entity.Owner;
      if (owner.MovementPoints < 1) return;

      if (owner instanceof Player) {
        if (owner.ViewMode == false) {
          owner.ToggleView();
          owner.ShootMode = true;
          return;
        } else {
          const target = LevelManager.Active.getActorsAtTile(
            owner.ViewTile.Index
          );
          if (target && (target[0] instanceof Player || Enemy)) {
            owner.CombatComponent.RangeAttackEntity(this, target[0]);
          }
        }
      }
    }
  }

  public Damage(): number {
    return 0;
  }

  public Armor(): number {
    return 0;
  }

  public AddDrawComponent(): void {
    if (!this.DrawComponent) {
      this.DrawComponent = new ItemDrawComponent();
      this.AddComponent(this.DrawComponent);
    }
  }

  public turn(): void {}
  public refresh(): void {}
  public over(): void {}
}
