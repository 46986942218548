import { Entity } from '@/utils';
import { Player } from '@/player';
import { BaseItem } from '@/items';

import { Enemy } from '@/enemy/enemy';
import { InventoryUIComponent } from '@/inventory/draw';
import { ItemUIComponent } from '@/items/draw/ui';
import { FactionManager } from '@/faction';

export class Inventory extends Entity {
  public Owner: Player | Enemy;
  public _entities: Set<any> = new Set<any>();
  public InvUi: InventoryUIComponent;
  public totalUsed = 0;
  public currentUsed = 0;

  public Player: Player;

  public Awake(): void {
    this.InvUi = new InventoryUIComponent();
    this.AddComponent(this.InvUi);

    for (let i = 0; i < 9; i++) {
      this._entities.add({ slot: i, occupied: false });
      this.totalUsed += 1;
    }
    this.GetItems();

    super.Awake();
  }

  public AddEntity(entity: Entity): void {}

  public GetItems(): any[] {
    return Array.from([...this._entities]);
  }

  public Update(deltaTime: number): void {
    super.Update(deltaTime);
  }
  public AddItem(newItem: BaseItem) {
    if (this.currentUsed > 8) {
      return;
    }

    for (let item of this._entities) {
      if (item.occupied == false) {
        item.occupied = newItem;
        this.totalUsed += 1;
        this.currentUsed += 1;
        newItem.Entity = this;
        this.Owner.BaseDamage += newItem._damage;
        this.Owner.BaseArmor += newItem._armor;
        this.Owner.MaxMovementPoints += newItem._mp;
        this.Owner.MaxHealth += newItem._maxHealth;
        this.Owner.Range += newItem._range;
        if (this.Owner instanceof Player) {
          newItem.AddComponent(new ItemUIComponent(item.slot));
        }
        newItem.Awake();

        return;
      }
    }
  }
  public RemoveItem(removedItem: BaseItem) {
    for (let item of this._entities) {
      if (item.slot == removedItem.Slot) {
        item.occupied = false;
        this.Owner.BaseDamage += removedItem._damage;
        this.Owner.BaseArmor += removedItem._armor;
        this.Owner.MovementPoints += removedItem._mp;
        this.Owner.MaxHealth += removedItem._maxHealth;
        this.Owner.Range += removedItem._range;
        const playerUI = FactionManager.Player.Inventory.InvUi._invUI;
        playerUI.removeChild(removedItem.UI._itemUI);
        playerUI.removeChild(removedItem.UI._quantityUI);
        this.currentUsed -= 1;
        return;
      }
    }
  }
}
