import { IComponent } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { Game } from '@/game';
import { GameLog } from '@/gameLog';
import { slotLocation } from '@/utils';

export class LegendUIComponent implements IComponent {
  public Entity: Game;
  public _legendUI: any;
  public _legendText: any;
  private _height: any = Settings.grid.height * Settings.tileSize;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._legendUI = new PIXI.Graphics();
    this._legendUI.beginFill(0xa9a9a9);
    this._legendUI.drawRect(
      1,
      (height / 11) * 9 - 1,
      width - 2,
      (height / 11) * 2 - 0.5
    );
    this._legendUI.zIndex = 1
    this._legendUI.endFill();

    const joystick = new PIXI.Text(`🕹️`, {
      fontFamily: Settings.ui.font,
      fontSize: 12,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    joystick.resolution = 2;
    joystick.y = (height / 11) * 9 + 2.5;
    joystick.x = 25.6;
    this._legendUI.addChild(joystick);

    this._legendUI.addChild(this.qKey());
    this._legendUI.addChild(this.wKey());
    this._legendUI.addChild(this.eKey());
    this._legendUI.addChild(this.aKey());
    this._legendUI.addChild(this.sKey());
    this._legendUI.addChild(this.dKey());
    this._legendUI.addChild(this.zKey());
    this._legendUI.addChild(this.xKey());
    this._legendUI.addChild(this.cKey());

    this._legendUI.addChild(this.tKey());
    this._legendUI.addChild(this.tText());

    this._legendUI.addChild(this.rKey());
    this._legendUI.addChild(this.rText());

    this._legendUI.addChild(this.vKey());
    this._legendUI.addChild(this.vText());

    this._legendUI.addChild(this.bKey());
    this._legendUI.addChild(this.bText());
    this._legendUI.addChild(this.mText());


    this.Draw();
  }

  public Update(deltaTime: number): void {}

  public Draw(): void {
    CanvasLayer.LeftUI.addChild(this._legendUI);
  }

  public qKey(): PIXI.Sprite {
    const spritePosition = [1, 1];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let qSprite = new PIXI.Sprite(texture);
    qSprite.setTransform(10, (this._height / 11) * 9 + 15, 1, 1);

    return qSprite;
  }

  public wKey(): PIXI.Sprite {
    const spritePosition = [3, 1];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let wSprite = new PIXI.Sprite(texture);
    wSprite.setTransform(25, (this._height / 11) * 9 + 15, 1, 1);

    return wSprite;
  }

  public eKey(): PIXI.Sprite {
    const spritePosition = [5, 1];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let eSprite = new PIXI.Sprite(texture);
    eSprite.setTransform(40, (this._height / 11) * 9 + 15, 1, 1);

    return eSprite;
  }

  public aKey(): PIXI.Sprite {
    const spritePosition = [1, 2];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(10, (this._height / 11) * 9 + 30, 1, 1);

    return Sprite;
  }

  public sKey(): PIXI.Sprite {
    const spritePosition = [3, 2];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(25, (this._height / 11) * 9 + 30, 1, 1);

    return Sprite;
  }

  public dKey(): PIXI.Sprite {
    const spritePosition = [5, 2];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(40, (this._height / 11) * 9 + 30, 1, 1);

    return Sprite;
  }

  public zKey(): PIXI.Sprite {
    const spritePosition = [1, 3];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(10, (this._height / 11) * 9 + 45, 1, 1);

    return Sprite;
  }
  public xKey(): PIXI.Sprite {
    const spritePosition = [3, 3];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(25, (this._height / 11) * 9 + 45, 1, 1);

    return Sprite;
  }

  public cKey(): PIXI.Sprite {
    const spritePosition = [5, 3];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(40, (this._height / 11) * 9 + 45, 1, 1);

    return Sprite;
  }
  public tKey(): PIXI.Sprite {
    const spritePosition = [9, 1];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(70, (this._height / 11) * 9, 1, 1);

    return Sprite;
  }

  public tText(): PIXI.Sprite {
    const text = new PIXI.Text(`take`, {
      fontFamily: Settings.ui.font,
      fontSize: 8,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    text.resolution = 2;

    text.setTransform(88, (this._height / 11) * 9 + 5.1, 1, 1);

    return text;
  }

  public rKey(): PIXI.Sprite {
    const spritePosition = [7, 1];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(70, (this._height / 11) * 9 + 15, 1, 1);

    return Sprite;
  }

  public rText(): PIXI.Sprite {
    const text = new PIXI.Text(`drop`, {
      fontFamily: Settings.ui.font,
      fontSize: 8,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    text.resolution = 2;

    text.setTransform(88, (this._height / 11) * 9 + 20.1, 1, 1);

    return text;
  }

  public vKey(): PIXI.Sprite {
    const spritePosition = [7, 3];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(70, (this._height / 11) * 9 + 30, 1, 1);

    return Sprite;
  }

  public vText(): PIXI.Sprite {
    const text = new PIXI.Text(`view`, {
      fontFamily: Settings.ui.font,
      fontSize: 8,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    text.resolution = 2;

    text.setTransform(88, (this._height / 11) * 9 + 35.1, 1, 1);

    return text;
  }

  public bKey(): PIXI.Sprite {
    const spritePosition = [9, 3];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.setTransform(70, (this._height / 11) * 9 + 45, 1, 1);

    return Sprite;
  }

  public bText(): PIXI.Sprite {
    const text = new PIXI.Text(`back`, {
      fontFamily: Settings.ui.font,
      fontSize: 8,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    text.resolution = 2;

    text.setTransform(88, (this._height / 11) * 9 + 50.1, 1, 1);

    return text;
  }

    public mText(): PIXI.Sprite {
    const text = new PIXI.Text(`M: 🔇`, {
      fontFamily: Settings.ui.font,
      fontSize: 6,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    text.resolution = 2;

    text.setTransform(115, (this._height / 11) * 9 + 57.1, 1, 1);

    return text;
  }

}
