import { Entity, randomInt } from '@/utils';
import { Level } from '@/level';
import { FactionManager } from '@/faction';
import * as TWEEN from '@tweenjs/tween.js';
import { LevelManager } from '@/levelManager';
import { Player } from '@/player';
import { tm } from '@/turnManager';
import { GameInputComponent } from '@/game/components';
import { Enemy } from '@/enemy/enemy';
import { GameLog } from '@/gameLog';
import * as PIXI from 'pixi.js';
import { LeatherBoots, WoodenArrow, WoodenShield, WoodenSword } from '@/items';
import { HealthPotion } from '@/items/consumable/healthPotion';
import { LevelContainerUIComponent } from '@/level/draw/ui';
import { LegendUIComponent } from '@/game/components';
import sound from 'pixi-sound';
import { Guitar } from '@/items/guitar';
import { Ocarina } from '@/items/ocarina';
import { Drums } from '@/items/drums';
import { StoneMask } from '@/items/stoneMask';
import { BunnyMask } from '@/items/bunnyMask';
import { SilverArrow } from '@/items/arrow/silverArrow';
import { WoodenBow } from '@/items/woodenBow';
import { SilverBow } from '@/items/silverBow';
import { DivinePotion } from '@/items/consumable/divinePotion';
import { ImpMask } from '@/items/impMask';
import { Harp } from '@/items/harp';
import { BlackFlag } from '@/items/blackFlag';
import { SilverSword } from '@/items/sword/silverSword';
import { SteelBoots } from '@/items/steelBoots';
import { SteelShield } from '@/items/steelShield';
import { Crystal } from '@/items/crystal';
import { Faerie } from '@/items/faerie';

export class Game extends Entity {
  private _entities: Entity[] = [];
  private static _gameLog: GameLog;

  public get Entities(): Entity[] {
    return this._entities;
  }

  constructor(level: Level) {
    super();
    this._entities.push(
      level,
      FactionManager.FriendlyFaction,
      FactionManager.EnemyFaction
    );
    this.Awake();
    this.PrepareEntities();
  }

  public static get Log(): GameLog {
    if (!this._gameLog) {
      this._gameLog = new GameLog();
    }

    return this._gameLog;
  }

  private _lastTimestamp = 0;

  public Awake(): void {
    super.Awake();
    this.AddComponent(new GameInputComponent());
    this.AddComponent(new LevelContainerUIComponent());
    this.AddComponent(new LegendUIComponent());
    this._entities.push(Game.Log);
    for (const entity of this.Entities) {
      entity.Awake();
    }
    window.requestAnimationFrame((timestamp: number) => {
      this._lastTimestamp = timestamp;

      this.Update(timestamp);
    });
  }

  public Update(timestamp: number): void {
    const deltaTime = (timestamp - this._lastTimestamp) / 1000;
    super.Update(deltaTime);

    for (const entity of this.Entities) {
      entity.Update(deltaTime);
    }
    for (const tile of LevelManager.Active.Tiles) {
      tile.Update(performance.now());
    }

    this._lastTimestamp = timestamp;
    TWEEN.update(timestamp);

    window.requestAnimationFrame(timestamp => this.Update(timestamp));

    if (tm.Over()) {
      tm.Refresh();
    }
    tm.Turn();
  }

  private PrepareEntities(): void {
    sound.Sound.from({
      url: 'src/assets/LoneWoods.mp3',
      autoPlay: true,
      loop: true,
    });

    Game.Log.Add('      ...you are lost...');
    Game.Log.Add('...to gather your bearings...');
    Game.Log.Add('  ...reach the 10th area...');

    const friendlyRandomTile = LevelManager.Active.randomWalkableTile();
    const friendlyTempNode =
      friendlyRandomTile &&
      LevelManager.Active.GetTileByIndex(friendlyRandomTile);

    const player = new Player(FactionManager.FriendlyFaction, friendlyTempNode);
    tm.addEntity(player);
    let item2 = new WoodenArrow();
    player.Inventory.AddItem(item2);
    let item4 = new HealthPotion();
    player.Inventory.AddItem(item4);

    let itemRandomTile8 = LevelManager.Active.randomOpenTile();
    let itemTempNode8 =
      itemRandomTile8 && LevelManager.Active.GetTileByIndex(itemRandomTile8);

    let baseItems = [
      Crystal,
      LeatherBoots,
      WoodenBow,
      WoodenShield,
      WoodenSword,
    ];
    let startItem = baseItems[randomInt(5)];
    let item8 = new startItem(itemTempNode8);

    LevelManager.Active._entites.push(item8);
    LevelManager.Active.InitStuff();
  }
}
