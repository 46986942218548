import { IComponent, randomInt } from '@/utils';
import { Tile } from '@/tile';
import { CanvasLayer } from '@/canvasLayer';
import * as PIXI from 'pixi.js';
import { Faction, FactionManager } from '@/faction';
import {
  makeForestGroundSprite,
  makeForestWallSprite,
} from '@/utils/factory/tileSprites/forestTileFactory';
import {
  makeMountainGroundSprite,
  makeMountainWallSprite,
} from '@/utils/factory/tileSprites/mountainTileFactory';
import {
  makeBeachGroundSprite,
  makeBeachWallSprite,
} from '@/utils/factory/tileSprites/beachTileFactory';
import { LevelManager } from '@/levelManager';

export class TileDrawComponent implements IComponent {
  public Entity: Tile;
  private _graphics: any;
  public _backgroundSprite: any;

  public Awake(): void {
    let sprite;
    if (this.Entity.Type === 'floor') {
      if (LevelManager.Active.Type == 'mountain') {
        sprite = makeMountainGroundSprite(
          this.Entity.Start.x,
          this.Entity.Start.y
        );
      }
      if (LevelManager.Active.Type == 'forest') {
        sprite = makeForestGroundSprite(
          this.Entity.Start.x,
          this.Entity.Start.y
        );
      }
      if (LevelManager.Active.Type == 'beach') {
        sprite = makeBeachGroundSprite(
          this.Entity.Start.x,
          this.Entity.Start.y
        );
      }
    } else {
      if (LevelManager.Active.Type == 'mountain') {
        sprite = makeMountainWallSprite(
          this.Entity.Start.x,
          this.Entity.Start.y
        );
      }
      if (LevelManager.Active.Type == 'forest') {
        sprite = makeForestWallSprite(this.Entity.Start.x, this.Entity.Start.y);
      }
      if (LevelManager.Active.Type == 'beach') {
        sprite = makeBeachWallSprite(this.Entity.Start.x, this.Entity.Start.y);
      }
    }
    this._backgroundSprite = sprite;
    this.Draw();
  }

  public Update(deltaTime: number): void {
    if (this.Entity.IsActive) {
      const player = FactionManager.Player;
      if (player.ShootMode == true && player.ViewTileDistance > player.Range) {
        this._backgroundSprite.tint = 0xdff2800;
        return;
      }

      this._backgroundSprite.tint = 0xe9967a;
    } else {
      this._backgroundSprite.tint = 0xffffff;
    }
  }

  private Draw(): void {
    CanvasLayer.Background.addChild(this._backgroundSprite);
  }
}
