import { Entity, Vector2D } from '@/utils';
import { TileDrawComponent } from '@/tile/components';

export class Tile extends Entity {
  public IsActive = false;
  public DrawComponent: TileDrawComponent
  public get Center(): Vector2D {
    return new Vector2D(
      this.Start.x + this.Size.x / 2,
      this.Start.y + this.Size.y / 2
    );
  }
  constructor(
    public readonly Start: Vector2D,
    public readonly End: Vector2D,
    public readonly Index: Vector2D,
    public readonly Type: string
  ) {
    super();
  }

  public get Size(): Vector2D {
    return new Vector2D(this.End.x - this.Start.x, this.End.y - this.Start.y);
  }

  public Awake(): void {
    this.DrawComponent = new TileDrawComponent()
    this.AddComponent(this.DrawComponent);

    super.Awake();
  }


  public Occupies(point: Vector2D): boolean {
    if (point.x < this.Start.x) {
      return false;
    }

    if (point.x > this.End.x) {
      return false;
    }

    if (point.y < this.Start.y) {
      return false;
    }

    if (point.y > this.End.y) {
      return false;
    }

    return true;
  }
}
