import { IComponent } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';

export class PlayerUIComponent implements IComponent {
  public Entity: Player;
  public _playerUI: any;
  public _playerStats: any;
  public Name: any;
  public Active: boolean;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._playerUI = new PIXI.Graphics();
    this._playerUI.beginFill(0xa9a9a9);
    this._playerUI.drawRect(1, 1, width - 2, height / 11+1);
    this._playerUI.endFill();

    this._playerUI.addChild(this._playerPortrait());
    this._playerUI.addChild(this._playerInfo());
    // this._playerUI.filters = [new OutlineFilter(1, 0x00000)];

    this.Draw();
  }

  public Update(deltaTime: number): void {
    this.Name.text = `  ${this.Entity.CombatComponent.Attack()}⚔️      ${this.Entity.CombatComponent.Armor()}🔰`;
    this.Name.style.fill = this.Active ? 0x8e5252 : 0x000000;
    this._playerStats.text = `${this.Entity.Health}/${
      this.Entity.MaxHealth+this.Entity._cMaxHealth
    }❤       ${this.Entity.MovementPoints+this.Entity._cMp}🐾`;
    this._playerStats.style.fill = this.Active ? 0x8e5252 : 0x000000;
  }

  private Draw(): void {
    CanvasLayer.RightUI.addChild(this._playerUI);
  }

  private _playerInfo(): PIXI.Text {
    const name = new PIXI.Text(`  ${this.Entity.CombatComponent.Attack()}⚔️      ${this.Entity.CombatComponent.Armor()}🔰`, {
      fontFamily: Settings.ui.font,
      fontSize: 8,
      align: 'center',
      verticalAlign: 'sub',
      padding: 1,
    });
    name.resolution = 2;
    name.position.x = 47;
    name.position.y = 7;
    this.Name = name;

    const stats = new PIXI.Text(
      `${this.Entity.Health}/${
        this.Entity.MaxHealth
      }❤        ${this.Entity.MovementPoints}👣`,
      {
        fontFamily: Settings.ui.font,
        fontSize: 8,
        align: 'center',
        fill: 0x000000,
        padding: 1,
      }
    );
    stats.resolution = 2;
    stats.position.y = 14;

    this._playerStats = stats;

    name.addChild(stats);

    return name;
  }

  private _playerPortrait(): PIXI.Sprite {
    const spritePosition = [0, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['player']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16 +0.5,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    const playerPortrait = new PIXI.Sprite(texture);
    playerPortrait.setTransform(0, 0, 2, 2);
    playerPortrait.position.y = 3.5;
    playerPortrait.position.x = 1;
    return playerPortrait;
  }
}
