import { IComponent, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { Inventory } from '@/inventory';
import { slotLocation } from '@/utils';
import { Game } from '@/game';
import { LevelManager } from '@/levelManager';
import { FactionManager } from '@/faction';

export class LevelContainerUIComponent implements IComponent {
  public Entity: Game;
  public _levelUI: any;
  public LevelPosition: any;
  public LevelIcons: any;
  public LevelName: any;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._levelUI = new PIXI.Graphics();
    this._levelUI.beginFill(0xa9a9a9);
    // this._levelUI.filters = [new OutlineFilter(3, 0x00000)];

    this._levelUI.drawRect(1, 1, width - 2, (height / 11) * 2);
    this._levelUI.endFill();
    // this._levelUI.y = 10;

    const slots = this._buildSlots();
    slots.forEach(slot => {
      this._levelUI.addChild(slot);
    });

    this.LevelPosition = this._levelPosition();
    this.LevelIcons = this._levelIcons(this.LevelPosition);
    this._levelUI.addChild(this.LevelIcons);

    this.LevelName = new PIXI.Text(this._buildName(), {
      fontFamily: Settings.ui.font,
      fontSize: 9,
      align: 'center',
      padding: 1,
    });
    this.LevelName.resolution = 2;
    this.LevelName.x = width / 2 + 7.7;
    this.LevelName.y = height / 11 - 4;
    this._levelUI.addChild(this.LevelName);
    this.Draw();
  }

  public Update(deltaTime: number): void {
    if (this.LevelIcons && this.LevelPosition) {
      this.LevelPosition = this._levelPosition();
      this.LevelIcons.text = this.LevelPosition;
    }
    if (this.LevelName) {
      this.LevelName.text = this._buildName();
    }
  }
  private Draw(): void {
    CanvasLayer.LeftUI.addChild(this._levelUI);
  }

  public _buildName(): string {
    const currentType = LevelManager.Active.Type;
    const currentPLevel = FactionManager.Player.Level;
    return `${currentType} ${currentPLevel}`;
  }

  private _buildSlots(): PIXI.Graphics[] {
    const slots: PIXI.Graphics[] = [];
    for (let i = 0; i < 9; i++) {
      let coords = slotLevelLocation(i);

      const slot = new PIXI.Graphics();
      if (i == 4) {
        slot.beginFill(0x8e5252);
      } else {
        slot.beginFill(0x9fa081);
      }
      slot.drawRoundedRect(coords.x, coords.y, 20, 20, 2);
      slot.endFill();

      slots.push(slot);
    }
    return slots;
  }
  private _levelPosition(): string {
    const levelArray = [];
    for (let i = 0; i < 9; i++) {
      let level;
      if (i == 4) {
        // console.log(LevelManager.Active)
        level = LevelManager.Active;
      } else {
        level = LevelManager.Levels[i];
      }
      if (level) {
        if (level.Type == 'mountain') {
          levelArray[i] = '️🌋  ';
        }
        if (level.Type == 'forest') {
          levelArray[i] = '🌲  ';
        }
        if (level.Type == 'beach') {
          levelArray[i] = '🏖️  ';
        }
      } else {
        levelArray[i] = '❔';
      }
      // console.log(levelArray, LevelManager.Levels)
    }
    const string = `${levelArray[0]}${levelArray[1]}${levelArray[2]}\n${
      levelArray[3]
    }${levelArray[4]}${levelArray[5]}\n${levelArray[6]}${levelArray[7]}${
      levelArray[8]
    }`;
    return string;
  }
  private _levelIcons(levelString: string): PIXI.Text {
    let levelIcons = new PIXI.Text(levelString, {
      fontFamily: '"Courier New", Courier, monospace',
      fontSize: 10,
      letterSpacing: 0.1,
      lineHeight: 23,
      padding: 1,

      // align: 'center',
    });
    levelIcons.x = 6.9;
    levelIcons.y = 6;
    levelIcons.resolution = 2;

    return levelIcons;
  }
}

export function slotLevelLocation(index: number) {
  const height = Settings.grid.height * Settings.tileSize;

  const realIndex = index;

  const y = Math.floor(realIndex / 3);
  const x = realIndex - y * 3;
  return new Vector2D(3 + 22 * x, 2 + y * 22);
}
