import { IComponent, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { Inventory } from '@/inventory';
import { slotLocation } from '@/utils';

export class InventoryUIComponent implements IComponent {
  public Entity: Inventory;
  public _invUI: any;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._invUI = new PIXI.Graphics();
    this._invUI.beginFill(0xa9a9a9);
    // this._invUI.filters = [new OutlineFilter(3, 0x00000)];

    this._invUI.drawRect(
      1,
      (height / 11) * 6.5 + 16.5,
      width - 2,
      (height / 11) * 4.5 + -28
    );
    this._invUI.endFill();
    this._invUI.y = 10;
    this._invUI.sortableChildren = true;

    const slots = this._buildSlots();
    slots.forEach(slot => {
      this._invUI.addChild(slot);
    });

    this._invUI.addChild(this.oneKey());
    this._invUI.addChild(this.twoKey());
    this._invUI.addChild(this.threeKey());
    this._invUI.addChild(this.fourKey());
    this._invUI.addChild(this.fiveKey());
    this._invUI.addChild(this.sixKey());
    this._invUI.addChild(this.sevenKey());
    this._invUI.addChild(this.eightKey());
    this._invUI.addChild(this.nineKey());
    this.Draw();
  }

  public Update(deltaTime: number): void {}

  private Draw(): void {
    CanvasLayer.RightUI.addChild(this._invUI);
  }

  private _buildSlots(): PIXI.Graphics[] {
    const slots: PIXI.Graphics[] = [];
    for (let i = 0; i < 9; i++) {
      let coords = slotLocation(i);

      const slot = new PIXI.Graphics();
      slot.beginFill(0x9fa081);
      slot.drawRoundedRect(coords.x, coords.y, 35, 35, 3);
      slot.endFill();

      slots.push(slot);
    }
    return slots;
  }

  public oneKey(): PIXI.Sprite {
    let location = slotLocation(0);
    const spritePosition = [1, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public twoKey(): PIXI.Sprite {
    let location = slotLocation(1);
    const spritePosition = [3, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public threeKey(): PIXI.Sprite {
    let location = slotLocation(2);
    const spritePosition = [5, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public fourKey(): PIXI.Sprite {
    let location = slotLocation(3);
    const spritePosition = [7, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public fiveKey(): PIXI.Sprite {
    let location = slotLocation(4);
    const spritePosition = [9, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public sixKey(): PIXI.Sprite {
    let location = slotLocation(5);
    const spritePosition = [11, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public sevenKey(): PIXI.Sprite {
    let location = slotLocation(6);
    const spritePosition = [13, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public eightKey(): PIXI.Sprite {
    let location = slotLocation(7);
    const spritePosition = [15, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }

  public nineKey(): PIXI.Sprite {
    let location = slotLocation(8);
    const spritePosition = [17, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['keyboard']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    let Sprite = new PIXI.Sprite(texture);
    Sprite.zIndex = 1;
    Sprite.setTransform(location.x + 24, location.y + 23, 0.75, 0.75);

    return Sprite;
  }
}
