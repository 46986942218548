import { IComponent, Vector2D } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';

export class PlayerDrawComponent implements IComponent {
  public Entity: Player;
  public _playerSprite: any;
  public Outline: any;

  private get Position(): Vector2D {
    const position = this.Entity.Position;
    if (!position) {
      throw new Error('Attempt to draw a player that has no Position');
    }

    return position;
  }

  public Awake(): void {
    const spritePosition = [1, 0];
    const texture = new PIXI.Texture(PIXI.utils.TextureCache['player']);
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    this._playerSprite = new PIXI.Sprite(texture);
    // this._playerSprite.height = 16;
    this._playerSprite.x = this.Entity.Start.x;
    this._playerSprite.y = this.Entity.Start.y;
    this.Outline = new OutlineFilter(0.7, 0x000000);

    this._playerSprite.filters = [this.Outline];
    this._playerSprite.anchor.set(-0.2, 0);
    this._playerSprite.zIndex = 3
    this.Draw();
  }

  public Update(deltaTime: number): void {
    this._playerSprite.x = this.Entity.Start.x - 0.5;
    this._playerSprite.y = this.Entity.Start.y - 1;

    // this.Outline.color = this.Entity.Active ? 0x50331b : 0x000000;
  }

  private Draw(): void {
    CanvasLayer.Foreground.addChild(this._playerSprite);
  }
}
