import { IComponent } from '@/utils';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { BaseItem } from '@/items';
import { slotLocation } from '@/utils';
import { FactionManager } from '@/faction';

export class ItemUIComponent implements IComponent {
  public Entity: BaseItem;
  public _quantityUI: any;
  public _itemUI: any;

  constructor(public slot: number) {}

  public Awake(): void {
    this.Entity.UI = this
    this.Entity.Slot = this.slot
    const coords = slotLocation(this.slot);

    this._itemUI = this.Entity.Sprite;
    this._itemUI.filters = [new OutlineFilter(4, 0x00000)];
    this._itemUI.zIndex = 0
    this._itemUI.setTransform(coords.x + 2, coords.y, 2, 2);
    if (this.Entity.Flipped) {
      this._itemUI.setTransform(coords.x + 33, coords.y, -2, 2);
    }

    if (this.Entity.Quantity > 0) {
      this._quantityUI = new PIXI.Text(`${this.Entity.Quantity}`, {
        fontFamily: Settings.ui.font,
        fontSize: 8,
        align: 'center',
        fill: 0x000000,
      });

      this._quantityUI.setTransform(coords.x + 4, coords.y + 2, 0, 0);
      this._quantityUI.resolution = 2;
    }

    this.Draw();
  }

  public Update(deltaTime: number): void {
    if (this.Entity.Quantity > 0) {
      this._quantityUI.text = `${this.Entity.Quantity}`
    }
  }

  private Draw(): void {
    const playerUI = FactionManager.Player.Inventory.InvUi._invUI;
    playerUI.addChild(this._itemUI);
    if (this._quantityUI) {
      playerUI.addChild(this._quantityUI);
    }
  }
}
