import { IComponent } from '@/utils';
import { Player } from '@/player';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';

export class EnemyUIComponent implements IComponent {
  public Entity: Player | any;
  public _enemyUI: any;
  public Name: any;
  public Stats: any;
  public Portrait: any;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._enemyUI = new PIXI.Graphics();
    this._enemyUI.beginFill(0xa9a9a9);
    this._enemyUI.drawRect(1, 1, width - 2, height / 20 - 3);
    this._enemyUI.endFill();

    this._enemyUI.addChild(this.Entity.Portrait);
    this._enemyUI.addChild(this._enemyName());
    this._enemyUI.addChild(this._enemyStats());
    // this._enemyUI.filters = [new OutlineFilter(1, 0x00000)];

    this.Draw();
  }

  public Update(deltaTime: number): void {
    this.Name.style.fill = this.Entity.Active ? 0x8e5252 : 0x000000;
    this.Stats.style.fill = this.Entity.Active ? 0x8e5252 : 0x000000;

    this.Stats.text = `${
      this.Entity.Health
    }❤ ${this.Entity.CombatComponent.Attack()}⚔🔰️${this.Entity.CombatComponent.Armor()} ${
      this.Entity.MovementPoints
    }🐾`;
  }

  private Draw(): void {}

  private _enemyStats(): PIXI.Text {
    const info = new PIXI.Text(
      `${
        this.Entity.Health
      }❤${this.Entity.CombatComponent.Attack()}⚔️${this.Entity.CombatComponent.Armor()}🔰${
        this.Entity.MovementPoints
      }🐾`,
      {
        fontFamily: Settings.ui.font,
        fontSize: 6,
        whitespace: 'nowrap',
        padding: 1,
      }
    );
    info.resolution = 2;
    info.position.x = 80;
    info.position.y = 6;

    this.Stats = info;

    return info;
  }

  private _enemyName(): PIXI.Text {
    const name = new PIXI.Text(`${this.Entity.Name}`, {
      fontFamily: Settings.ui.font,
      fontSize: 5,
      whitespace: 'nowrap',
    });
    name.resolution = 2;
    name.position.x = 16;
    name.position.y = 6;

    this.Name = name;

    return name;
  }
}
