import { OnClickComponent, Vector2D } from '@/utils';
import { Level } from '@/level';
import { Settings } from '@/settings';
import { LevelManager } from '@/levelManager';

export class LevelOnClickComponent extends OnClickComponent {
  public Entity: Level;

  public Awake(): void {
    // @todo
  }

  public Update(deltaTime: number): void {
    // @todo
  }

  public ClickOn(point: Vector2D): void {
    point.x =
      (point.x - Settings.ui.width * Settings.tileSize * Settings.scale) / 2;
    point.y = point.y / 2;
    for (const tile of this.Entity.Tiles) {
      // tile.IsActive = tile.Occupies(point);
      if (tile.IsActive) {

      }
    }
  }
}
