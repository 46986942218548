import {Settings} from "@/settings";
import {Vector2D} from "@/utils";

export function slotLocation(index: number) {
    const height = Settings.grid.height * Settings.tileSize;

    const realIndex = index;

    const y = Math.floor(realIndex / 3);
    const x = realIndex - y * 3;
    return new Vector2D(10 + 40 * x, (height / 11) * 6.5 + 22 + 38 * y);
  }
