import { IComponent } from '@/utils';
import { CanvasLayer } from '@/canvasLayer';
import { Settings } from '@/settings';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';
import { Game } from '@/game';
import { GameLog } from '@/gameLog';

export class GameLogUIComponent implements IComponent {
  public Entity: GameLog;
  public _gameLogUI: any;
  public _logText: any;

  public Awake(): void {
    const width = Settings.ui.width * Settings.tileSize;
    const height = Settings.grid.height * Settings.tileSize;
    this._gameLogUI = new PIXI.Graphics();
    this._gameLogUI.beginFill(0xa9a9a9);
    this._gameLogUI.drawRect(
      1,
      (height / 11) * 2 + 2,
      width - 2,
      (height / 11) * 7 - 4
    );
    this._gameLogUI.endFill();

    let text = Game.Log.Messages.join(`\n\n`);

    const logText = new PIXI.Text(text, {
      fontFamily: Settings.ui.font,
      fontSize: 6,
      align: 'left',
      lineHeight: 6,
      padding: 1,

    });
    this._logText = logText;
    this._logText.resolution = 2;
    (this._logText.position.y = (height / 11) * 2 + 6),
      (this._logText.position.x = 8);
    this._gameLogUI.addChild(logText);


    this.Draw();
  }

  public Update(deltaTime: number): void {
    this._logText.text = Game.Log.Messages.join(`\n\n`);
  }

  public Draw(): void {
    CanvasLayer.LeftUI.addChild(this._gameLogUI);
  }
}
