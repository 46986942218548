import { Entity, Vector2D } from '@/utils';
import { FactionManager } from '@/faction';
import { Faction } from '@/faction';
import { PlayerLocomotionComponent } from '@/player';
import { EnemyDrawComponent } from '@/enemy/draw/draw';
import { Tile } from '@/tile';
import * as PF from '../assets/pathfinding-browser';
import { devRoom } from '@/utils';
import { LevelManager } from '@/levelManager';
import * as TWEEN from '@tweenjs/tween.js';
import { EnemyUIComponent } from '@/enemy/draw/ui';
import { PlayerCombatComponent } from '@/player/components/combat/combat';
import { Inventory } from '@/inventory';
import { tm } from '@/turnManager';
import { Enemy } from '@/enemy/enemy';
import * as PIXI from 'pixi.js';
import { OutlineFilter } from '@pixi/filter-outline';

export class DarkRider extends Enemy {
  public Name: string = 'dark rider';
  public _start: Vector2D | undefined;
  public _locomotionComponent: PlayerLocomotionComponent;
  public CombatComponent: PlayerCombatComponent;
  public UIComponent: EnemyUIComponent;
  public Blocking: true = true;
  public Active: boolean;
  public Inventory: Inventory;
  public Range: number = 3;
  public ViewTileDistance: number | null;
  public Portrait: any;
  public Preview: any;
  public _enemySprite: any;
  public Outline: any;
  public Health: number = 8;
  public MaxHealth: number = 8;
  public BaseArmor: number = 3;
  public BaseDamage: number = 9;
  public Description: string = `${
      this.Health
    }❤ ${this.BaseDamage}⚔🔰️${this.BaseArmor} ${
      this.MovementPoints
    }🐾`

  public get Position(): Vector2D | null {
    return this._locomotionComponent.Position;
  }

  public get Index(): Vector2D | null {
    return this._locomotionComponent.Index;
  }

  public get Center(): Vector2D | null {
    return this._locomotionComponent.Center;
  }

  public get Start(): Vector2D | undefined {
    return this._start;
  }

  public set Start(start: Vector2D | undefined) {
    this._start = start;
  }

  public get Tile(): Tile | null {
    return this._locomotionComponent.Tile;
  }
  constructor(
    public readonly faction: Faction,
    tile: Tile,
    public MovementPoints: number = 2,
    public Moved: boolean = false
  ) {
    super(faction, tile);

    this._locomotionComponent = new PlayerLocomotionComponent();
    this._locomotionComponent.Tile = tile;
    this.faction.AddEntity(this);
    // this.faction.Awake()
    this.CombatComponent = new PlayerCombatComponent();
    this.UIComponent = new EnemyUIComponent();
  }

  public Awake(): void {
    this.EnemySprite();
    this.AddComponent(this._locomotionComponent);
    this.AddComponent(new EnemyDrawComponent());
    this.AddComponent(this.UIComponent);
    this.AddComponent(this.CombatComponent);
    this._enemyPortrait();

    if (this.Tile) {
      const start = new Vector2D(this.Tile.Start.x, this.Tile.Start.y);
      this._start = start;
      tm.addEntity(this);
    }

    super.Awake();
  }

  public Update(deltaTime: number): void {
    super.Update(deltaTime);

    for (const component of this.Components) {
      component.Update(deltaTime);
    }
  }

  public Turn(): void {
    if (this.MovementPoints > 0 && TWEEN.getAll().length == 0) {
      const grid = new PF.Grid(devRoom);
      const finder = new PF.AStarFinder({ allowDiagonal: true });
      const playerIndex = FactionManager.Player.Tile.Index;
      const path = finder.findPath(
        this.Tile.Index.x,
        this.Tile.Index.y,
        playerIndex.x,
        playerIndex.y,
        grid
      );
      if (path.length == 0 || path.length == 1) {
        this.Moved = false;
        this.MovementPoints -= 1;
        return;
      }
      const targetIndex = new Vector2D(path[1][0], path[1][1]);
      if (path.length > 2) {
        if (!LevelManager.Active.isWalkableTile(targetIndex)) {
          //TODO add wait, animation?
          this.Moved = false;
          this.MovementPoints -= 1;
          return;
        }
        this._locomotionComponent.MoveToTile(targetIndex);
        return;
      }
      if (path.length <= 2) {
        const targetEntities = LevelManager.Active.getEntitiesAtTile(
          targetIndex
        );
        if (targetEntities && targetEntities.length > 0) {
          this.CombatComponent.AttackEntity(
            targetEntities[0].GetComponent(PlayerCombatComponent)
          );
          return;
        }
      }
      this.Moved = false;
      this.MovementPoints -= 1;
    }
  }

  public Over(): boolean {
    const isOver = this.MovementPoints < 1 && !this.Moved;

    if (isOver && this.UIComponent) {
      this.Active = false;
    } else {
      this.Active = true;
    }
    return isOver;
  }
  public Refresh(): void {
    this.MovementPoints = 2;
    this.Moved = false;
  }

  public _enemyPortrait(): PIXI.Sprite {
    const spritePosition = [6, 0];
    const texture = new PIXI.Texture(
      PIXI.utils.TextureCache['undeadEnemyIcon']
    );
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    const enemyPortrait = new PIXI.Sprite(texture);
    enemyPortrait.setTransform(1, 0, 0.75, 0.75);
    this.Portrait = enemyPortrait;
    this.Portrait.z = 0;
    return enemyPortrait;
  }


  public EnemySprite(): PIXI.Sprite {
    const spritePosition = [0, 15];
    const texture = new PIXI.Texture(
      PIXI.utils.TextureCache['undeadEnemy']
    );
    const rectangle = new PIXI.Rectangle(
      spritePosition[0] * 16,
      spritePosition[1] * 16,
      16,
      16
    );
    texture.frame = rectangle;
    this._enemySprite = new PIXI.Sprite(texture);
    this._enemySprite.height = 16

    this.Outline = new OutlineFilter(1, 0x00000);
    this._enemySprite.filters = [this.Outline];
    return this._enemySprite;
  }
}
